import {
  Components,
  OrthoPerspectiveCamera,
  SimpleGrid,
  SimpleRaycaster,
  SimpleRenderer,
  SimpleScene
} from 'openbim-components'
import * as THREE from 'three'
import { Color } from 'three'
import settings from '@/config/scene'
import addDefaultLights from '@/controllers/mountViewer/add-default-lights'

/** It starts the needed parts for new 2D-viewer */
export default async wrapper => {
  const viewer = new Components()

  viewer.scene = new SimpleScene(viewer)
  viewer.scene.get().background = new Color(settings.global.backgroundColor)

  addDefaultLights(viewer.scene.get())

  const renderer = new SimpleRenderer(viewer, wrapper)

  renderer._renderer.setSize(window.innerWidth, window.innerHeight, true)

  viewer.renderer = renderer

  const camera = new OrthoPerspectiveCamera(viewer)

  await camera.toggleProjection()

  camera.controls.setLookAt(0, 50, 0, 0, 0, 0)

  camera.setNavigationMode('Plan')
  viewer.camera = camera

  viewer.raycaster = new SimpleRaycaster(viewer)

  viewer.init()

  new SimpleGrid(viewer, new THREE.Color('#5e7695'), 5, 5, 100)

  return { viewer2D: viewer }
}
