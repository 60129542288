/** Its goal is to draw a target for zoom-window feature
 * @param context canvas 2d environment, needed to draw
 * @param center coordinates about middle x,y point of zoom-window */
export default (context, center) => {
  context.beginPath()
  context.arc(center.x, center.y, 2.5, 0, 2 * Math.PI)

  context.moveTo(center.x, center.y)
  context.lineTo(center.x + 10, center.y)
  context.lineTo(center.x - 10, center.y)
  context.moveTo(center.x, center.y)
  context.lineTo(center.x, center.y + 10)
  context.lineTo(center.x, center.y - 10)

  context.setLineDash([2.5])

  context.stroke()
}
