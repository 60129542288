import store from '../store'

/** Switches visible state of selected geometries */
export default ifcAssets => {
  try {
    const { fragments, hiddenExpressIds, culler } = store.getters

    const list = Object.values(fragments.list)

    const getFragment = expressId => {
      return list
        .filter(l => l !== undefined)
        .find(l => l.items.includes(expressId))
    }

    /** Loop selected express-ids */
    for (const asset of ifcAssets) {
      const { expressId } = asset

      if (!hiddenExpressIds.includes(expressId)) {
        hiddenExpressIds.push(expressId)

        const fragment = getFragment(expressId)
        fragment.setVisibility(false)
      } else {
        const index = hiddenExpressIds.indexOf(expressId)
        hiddenExpressIds.splice(index, 1)

        const hiddenGeoms = list
          .filter(l => l !== undefined)
          .find(l => Object.keys(l.hiddenInstances).includes(expressId))

        if (hiddenGeoms) {
          hiddenGeoms.setVisibility(true)
        } else {
          const fragment = getFragment(expressId)
          fragment.setVisibility(true)
        }
      }

      culler.needsUpdate = true
    }
  } catch (error) {
    console.error(error)
  }
}
