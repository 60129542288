import { DoubleSide } from 'three'
import store from '@/store'

export default () => {
  const { viewer, transparencyRate } = store.getters

  const cast = viewer.raycaster.castRay()

  if (cast) {
    const {
      object: { material }
    } = cast

    for (const m of material) {
      m.opacity = transparencyRate / 10
      m.transparent = true
      m.side = DoubleSide
    }
  }
}
