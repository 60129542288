import draw from '@/controllers/mountZoomWindow/draw'
import store from '@/store'
import { Box3, Raycaster, Vector2, Vector3 } from 'three'

export default () => {
  const {
    viewer2D,
    tools: { clipperTool2 },
    ifcLevel: { baseHeight, height },
    fragmentsHighlighter,
    fragments
  } = store.getters

  const canvas = document.getElementById('selection-window')

  canvas.height = window.innerHeight
  canvas.width = window.innerWidth

  const context = canvas.getContext('2d')

  context.strokeStyle = 'blue'

  let initialX, initialY, center

  let isFirstCast = false

  const points = {
    start: null,
    end: null
  }

  let isDrawing = false

  const begin = () => {
    // todo: anular interacción en right-click y desplazar cámara
    // if (event.button === 2) {
    //   event.preventDefault()
    //
    //   canvas.style.zIndex = '-1'
    //   return
    // }

    isDrawing = true
    context.beginPath()

    setTimeout(() => {
      if (!isFirstCast) {
        isDrawing = false

        const event = new CustomEvent('highlight-express-ids', {
          detail: { selection: {}, removePrevious: true }
        })

        document.dispatchEvent(event)

        fragmentsHighlighter.highlightByID('highlight', {}, true)
      }
    }, 200)
  }

  canvas.addEventListener('mousedown', begin)

  canvas.addEventListener('mousemove', event => {
    if (!isDrawing) return

    const _ = draw(
      event,
      canvas,
      context,
      center,
      initialX,
      initialY,
      isDrawing,
      false
    )

    const rect = canvas.getBoundingClientRect()

    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    const mouse = new Vector2(
      (x / (canvas.width / 2)) * 2 - 1,
      -(y / canvas.height) * 2 + 1
    )

    const raycaster = new Raycaster()

    raycaster.setFromCamera(mouse, viewer2D.camera.activeCamera)

    const intersects = raycaster.intersectObjects([
      clipperTool2._planes[0].meshes[0]
    ])

    if (!initialX && !initialY) {
      clipperTool2._planes[0].size = Number.MAX_SAFE_INTEGER
      clipperTool2._planes[1].size = 0
      clipperTool2.visible = true

      if (isFirstCast === false) {
        setTimeout(() => {
          const _ = raycaster.intersectObjects([
            clipperTool2._planes[0].meshes[0]
          ])

          if (_.length) {
            points.start = _[0].point

            isFirstCast = true
          }
        }, 100)
      }

      initialX = _.initialX
      initialY = _.initialY
    } else if (isFirstCast) {
      if (intersects.length) {
        points.end = intersects[0].point
      }
    }
  })

  const endDrawing = () => {
    context.clearRect(0, 0, canvas.width, canvas.height)

    if (points.start && points.end) {
      const max = {
        x: Math.max(points.start.x, points.end.x),
        y: Math.max(points.start.y, height - baseHeight),
        z: Math.max(points.start.z, points.end.z)
      }

      const min = {
        x: Math.min(points.start.x, points.end.x),
        y: Math.min(points.start.y, points.end.y),
        z: Math.min(points.start.z, points.end.z)
      }

      const box = new Box3(
        new Vector3(min.x, min.y, min.z),
        new Vector3(max.x, max.y, max.z)
      )

      const selection = {}

      const checkSelected = group => {
        const isCapture = points.start.x > points.end.x

        const { id, vectors } = group

        let notFound = false

        for (const point of vectors) {
          if (isCapture) {
            if (box.containsPoint(point)) {
              if (selection[id] === undefined) {
                Object.assign(selection, {
                  [id]: fragments.list[id].items
                })
              }

              break
            }
          } else {
            if (!box.containsPoint(point)) {
              notFound = true
              break
            }
          }
        }

        if (!notFound && !isCapture) {
          if (selection[id] === undefined) {
            Object.assign(selection, {
              [id]: fragments.list[id].items
            })
          }
        }
      }

      const vertices = []

      // const mat = new MeshBasicMaterial({ color: 0xff0000 })
      // const mat2 = new MeshBasicMaterial({ color: 0x0000ff })

      for (const mesh of viewer2D.culler.visibleMeshes) {
        // todo: check mesh.count === 1 case

        // if (mesh.count === 1) mesh.material = mat
        // else mesh.material = mat2

        const bufferVertices = mesh.geometry.attributes.position.array

        const innerVertices = { id: mesh.uuid, vectors: [] }

        for (let i = 0; i < bufferVertices.length; i += 3) {
          innerVertices.vectors.push(
            new Vector3(
              bufferVertices[i],
              baseHeight + (height - baseHeight) / 2,
              bufferVertices[i + 2]
            )
          )
        }

        vertices.push(innerVertices)
      }

      for (const group of vertices) {
        checkSelected(group)
      }

      const event = new CustomEvent('highlight-express-ids', {
        detail: { selection, removePrevious: true }
      })

      document.dispatchEvent(event)

      fragmentsHighlighter.highlightByID('highlight', selection, true)
    }

    points.start = null
    points.end = null

    isDrawing = false

    initialX = undefined
    initialY = undefined

    clipperTool2.visible = false

    isFirstCast = false
  }

  canvas.addEventListener('mouseup', endDrawing)
}
