import store from '@/store'

export default () => {
  const { fragments } = store.getters

  const renderedExpressIds = []

  for (const f in fragments.list) {
    for (const item of fragments.list[f].items) {
      if (!renderedExpressIds.find(r => r.expressId === item)) {
        renderedExpressIds.push({
          expressId: item,
          uuid: fragments.list[f].id
        })
      }
    }
  }

  store.dispatch('setRenderedExpressIds', renderedExpressIds)
}
