export default {
  inputIfcLevel: {
    base: {
      show: false,
      value: 0
    },
    height: {
      show: false,
      value: 0
    }
  },
  isBothViewers: false,
  isPerspective: false,
  previousXPos: 0,
  previousYPos: 0,
  processedBucketFiles: null,
  rightFloating: 0,
  selectionWindow: false,
  show: false,
  subViewerInstanced: false,
  topFloating: 0
}
