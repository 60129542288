<template>
  <div>
    <div
      @click="handleShow"
      class="structure-item"
      :title="`${item.type}: ExpressID: ${item.expressID}`"
    >
      <img
        v-if="childLength"
        :src="
          show
            ? 'https://cdn-icons-png.flaticon.com/16/1383/1383970.png'
            : 'https://cdn-icons-png.flaticon.com/16/992/992667.png'
        "
        alt=""
      />
      <img v-else src="https://cdn-icons-png.flaticon.com/16/274/274383.png" />
      <p style="margin-left: 8px" v-text="item.type" />
    </div>
    <div v-show="show" style="margin-left: 8px" v-if="item.children">
      <StructurePanel
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
      />
    </div>
  </div>
</template>

<script>
import Store from '@/store'

export default {
  name: 'StructurePanel',

  computed: {
    childLength() {
      return Object.keys(this.item.children).length
    },

    structure() {
      return Store.getters.structure
    }
  },

  data() {
    return {
      show: false
    }
  },

  methods: {
    handleShow() {
      if (this.childLength) {
        this.show = !this.show
      }
    }
  },

  props: ['item']
}
</script>
