/** References for loop and classify files */
export default {
  properties: 'properties.json'
  // Deprecated at libs
  // levelRelationships: 'levels-relationship.json',
  // itemTypes: 'model-types.json',
  // allTypes: 'all-types.json',
  // floorsProperties: 'levels-properties.json',
  // coordinationMatrix: 'coordination-matrix.json',
  // boundingBoxes: 'bounding-boxes-opaque.json',
  // transparentBoundingBoxes: 'bounding-boxes-transparent.json',
  // expressIDFragmentIDMap: 'express-fragment-map.json'
}
