export default [
  { title: 'saoBias', value: '0.5', min: '0', max: '1', step: '0.05' },
  {
    title: 'saoBlurDepthCutoff',
    value: '0.0015',
    min: '0',
    max: '1',
    step: '0.001'
  },
  { title: 'saoBlurRadius', value: '8', min: '0', max: '50', step: '1' },
  { title: 'saoBlurStdDev', value: '4', min: '0', max: '50', step: '1' },
  { title: 'saoIntensity', value: '0.02', min: '0', max: '1', step: '0.001' },
  { title: 'saoScale', value: '30', min: '0', max: '100', step: '1' }
]
