import store from '@/store'

export default () => {
  const { viewer } = store.getters

  // viewer.raycaster.mouse.offset = { x: 0, y: 0 }

  const cast = viewer.raycaster.castRay()

  if (cast) {
    const {
      object: { uuid }
    } = cast

    console.log('⭐uuid⭐', uuid)
    /**
     /// ADAPTAR ENDPOINT PARA VOLUME EN JAVA
     // const volume = await getVolume(this.bucketId, uuid)

     // if (volume) {
     //   console.log('volume: ', volume)
     //   return volume
     // }
     */
  }
}
