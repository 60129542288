import store from '@/store'

export default () => {
  const {
    getters: {
      traceLines,
      tools: { dimensionsTool }
    }
  } = store

  dimensionsTool.enabled = true
  dimensionsTool.snapDistance = 1
  dimensionsTool.setTraceLines(traceLines)
}
