import { FragmentHighlighter } from 'openbim-components'
import { DoubleSide, MeshBasicMaterial } from 'three'
import store from '@/store'

export default (viewer, fragments, isSubViewer = false) => {
  const fragmentsHighlighter = new FragmentHighlighter(viewer, fragments)
  fragmentsHighlighter.update()

  /** @satisfies Highlights materials  */
  const highLightMat = new MeshBasicMaterial({
    transparent: true,
    opacity: 0.5,
    color: '#08f',
    side: DoubleSide,
    depthTest: false
  })

  const preselectionMat = new MeshBasicMaterial({
    transparent: true,
    opacity: 0.15,
    color: '#f0f',
    side: DoubleSide,
    depthTest: false
  })

  fragmentsHighlighter.add('highlight', [highLightMat])
  fragmentsHighlighter.add('preselection', [preselectionMat])

  if (isSubViewer) {
    return fragmentsHighlighter
  } else {
    store.dispatch('setFragmentsHighlighter', fragmentsHighlighter)
  }
}
