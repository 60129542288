import store from '@/store'
import handleAngleMode from '@/controllers/modes/mouse-click/handle-angle-mode'
import handleVolumeTraceMode from '@/controllers/modes/mouse-click/handle-volume-trace-mode'
import handlePointMode from '@/controllers/modes/mouse-click/handle-point-mode'
import handleSnapMode from '@/controllers/modes/mouse-click/handle-snap-mode'
import handleZoomObjectMode from '@/controllers/modes/mouse-click/handle-zoom-object-mode'
import handleTransparentPicker from '@/controllers/modes/mouse-click/handle-transparent-picker'
import handleVolumeMode from '@/controllers/modes/mouse-click/handle-volume-mode'
import handleHighlight from '@/controllers/modes/mouse-click/handle-highlight'
import handlePropertiesPickingMode from '@/controllers/modes/mouse-click/handle-properties-picking-mode'
import getActiveMode from '@/helpers/get-active-mode'

export default async event => {
  const activeMode = getActiveMode()
  if (!activeMode) return

  switch (activeMode) {
    /** Angle measurement mode */
    case 'angleMode':
      await handleAngleMode()
      break

    /** Area mode */
    case 'areaMode':
      const { areaTool } = store.getters.tools
      areaTool.create()
      break

    /** Highlight assets on click */
    case 'picker':
    case 'pickingMode':
      handleHighlight(event)
      break

    /** Area mode */
    case 'pointMode':
      await handlePointMode()
      break

    /** Shows object properties panel */
    case 'propertiesPickingMode':
      await handlePropertiesPickingMode()
      break

    /** Display distances mode */
    case 'snapMode':
      await handleSnapMode()
      break

    /** Transparent geometries on click */
    case 'transparentPicker':
      await handleTransparentPicker()
      break

    /** Get volume related metadata */
    case 'volumeMode':
      handleVolumeMode()
      break

    /** Interactive Prism volume-measurement */
    case 'volumeTraceMode':
      await handleVolumeTraceMode()
      break

    /** Zoom object */
    case 'zoomObjectMode':
      await handleZoomObjectMode()
      break
  }

  const {
    fpMode,
    pointMode,
    snapMode,
    viewer: { camera }
  } = store.getters

  /** Recovers cancelled camera-controls at evaluated modes */
  if (!snapMode && !pointMode && !fpMode && !camera.controls.enabled) {
    camera.controls.enabled = true
  }
}
