<template>
  <div>
    <div>
      <nav class="navbar">
        <details>
          <summary
            style="font-weight: bold; background: rgba(73, 130, 191, 0.24)"
            v-text="'IFC Processing'"
          />
          <details>
            <summary
              class="sub-summary"
              v-text="'1 Process ifc from url & create a bucket'"
            />
            <input
              type="text"
              class="text-area"
              placeholder="ID-Bucket"
              @input="_ownBucketId($event.target.value)"
            />
            <input
              type="text"
              class="text-area"
              placeholder="Model name"
              @input="_modelName($event.target.value)"
            />
            <input
              class="text-area"
              style="text-overflow: ellipsis; padding-right: 10rem"
              placeholder="IFC file path"
              :value="ifcPath"
              @input="_ifcPath($event.target.value)"
            />
            <button
              class="button-text-area"
              @click="_dispatch('ifcProcess', ownBucketId, ifcPath, modelName)"
              style="margin-top: 3px"
              :style="
                ownBucketId === '' || modelName === ''
                  ? 'pointer-events: none; opacity: 0.5'
                  : ''
              "
              v-text="'Continue'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'2 List & select buckets'" />
            <button
              @click="_dispatch('listBuckets')"
              class="button-text-area"
              v-text="'List buckets'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; position: relative"
              name="IfcCategories"
              size="20"
            >
              <option
                v-for="(b, index) of buckets"
                :key="index"
                @click="_bucketId($event.target.value)"
                v-text="b"
              />
            </select>
            <p style="font-size: 14px">
              Selected bucket:
              <strong>{{ bucketId }}</strong>
            </p>
            <hr />
            <p style="font-size: 14px" v-text="'Bucket Models'" />
            <select
              class="data-list"
              style="margin-top: 3px; position: relative"
              size="20"
              v-model="selectedModels"
              multiple
            >
              <option
                v-for="(bucketModel, index) of bucketModels"
                :key="index"
                :value="bucketModel"
                v-text="bucketModel"
              />
            </select>
          </details>
          <details>
            <summary
              class="sub-summary"
              v-text="'3 Obtain IFC-Classes & attributes list'"
            />
            <button
              @click="_dispatch('getIfcClasses', bucketId)"
              class="button-text-area"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="'Load IFC classes'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; position: relative"
              name="IfcCategories"
              size="20"
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(c, index) of ifcClasses"
                :key="index"
                @click="_dispatch('getAttributes', bucketId, c)"
                v-text="c"
              />
            </select>
          </details>
          <details>
            <summary
              class="sub-summary"
              v-text="'4 Get IFC-Class context list'"
            />
            <button
              @click="_dispatch('getContextList', bucketId)"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Load context ifc classes'"
            />
            <select class="data-list" style="margin-top: 3px" size="20">
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d; user-select: none"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(ifcClass, index) of contextIfcClasses"
                :key="index"
                v-text="ifcClass"
              />
            </select>
          </details>
          <details>
            <summary class="sub-summary" v-text="'5 Set context categories'" />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              multiple
              v-model="sContextIfcClasses"
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(c, index) of ifcClasses"
                :key="index"
                v-text="c"
              />
            </select>
            <input
              type="checkbox"
              class="label-text"
              id="chkContext"
              :checked="sIsContextIfcClasses"
              @change="_isContextIfcClasses"
            />
            <label
              class="label-text"
              for="chkContext"
              v-text="'Selected elements will be set as context'"
            />
            <button
              @click="
                _dispatch(
                  'setContext',
                  bucketId,
                  sContextIfcClasses,
                  sIsContextIfcClasses
                )
              "
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Set as context'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'6 Get IFC-Class assets'" />
            <input
              class="text-area"
              style="height: 30px; margin-top: 3px"
              placeholder="Ifc-class to explore (i.e. IFCFURNITURE)"
              v-model="sIfcClassToExplore"
            />
            <button
              @click="_dispatch('getIfcAssets', bucketId, sIfcClassToExplore)"
              class="button-text-area"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="'Load related ifc-class Geometries'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; height: 200px"
              size="60"
              v-model="sObjectsByIfcClass"
              multiple
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(asset, index) of ifcClassExpressIds"
                :key="index"
                :value="asset"
                v-text="`${index} - ${asset.model}`"
              />
            </select>
          </details>
          <details>
            <summary class="sub-summary" v-text="'7 Object Properties'" />
            <button
              @click="_dispatch('togglePropertiesPicker')"
              class="button-text-area"
              v-text="'Pick for object properties'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'8 Delete bucket assets'" />
            <button
              @click="
                _dispatch('markAsDeletedAssets', bucketId, sObjectsByIfcClass)
              "
              class="button-text-area"
              style="margin-top: 3px"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="'Delete selected object at previous field)'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; height: 200px"
              size="60"
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-if="!deletedBucketObjects.length && bucketId !== ''"
                v-text="'Click to load the list...'"
              />
              <option
                v-for="({ expressId, model }, index) of deletedBucketObjects"
                :key="index"
                v-text="`Model: ${model} - Express id: ${expressId}`"
                disabled
              />
            </select>
            <button
              @click="_dispatch('listDeletedAssets', bucketId)"
              class="button-text-area"
              style="margin-top: 3px"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="'List deleted Ifc assets at bucket'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'9 Restore deleted assets'" />
            <button
              @click="
                _dispatch('restoreDeletedAssets', bucketId, assetsToRestore)
              "
              class="button-text-area"
              style="margin-top: 3px"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="
                `Restore ${
                  selectedAssetsToRestore.length
                    ? selectedAssetsToRestore.length
                    : ''
                } selected `
              "
            />
            <select
              class="data-list"
              style="margin-top: 3px; height: 200px"
              size="60"
              v-model="assetsToRestore"
              multiple
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-if="!deletedBucketObjects.length && bucketId !== ''"
                v-text="'Click to load the list...'"
              />
              <option
                v-for="({ expressId, model }, index) of deletedBucketObjects"
                :key="index"
                :value="{ expressId, model }"
                v-text="`Model: ${model} - Express id: ${expressId}`"
              />
            </select>
            <button
              @click="_dispatch('listDeletedAssets', bucketId)"
              class="button-text-area"
              style="margin-top: 3px"
              v-text="'List deleted Ifc assets at bucket'"
            />
          </details>
          <details>
            <summary
              class="sub-summary"
              v-text="'10 Restore entire bucket files'"
            />
            <button
              @click="_dispatch('restoreBucket', bucketId)"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Restore'"
            />
          </details>
        </details>
        <details>
          <summary
            style="font-weight: bold; background: rgba(84, 142, 41, 0.31)"
            v-text="'Shows data at the viewer'"
          />
          <details>
            <summary class="sub-summary" v-text="'1 Render IFC from browser'" />
            <!--            <input-->
            <!--              id="show-structure"-->
            <!--              type="checkbox"-->
            <!--              :checked="sShowStructure"-->
            <!--              @change="_showStructure"-->
            <!--            />-->
            <!--            <label-->
            <!--              for="show-structure"-->
            <!--              class="label-text"-->
            <!--              v-text="'Show structure-tree'"-->
            <!--            />-->
            <button
              @click="showBrowser()"
              style="margin-top: 1rem"
              class="button-text-area"
              v-text="'Load file'"
            />
          </details>
          <details>
            <summary
              class="sub-summary"
              v-text="'2 Show assets at the viewer'"
            />
            <input
              class="text-area"
              style="height: 30px; margin-top: 3px"
              placeholder="Ifc-class to explore (i.e. IFCFURNITURE)"
              v-model="sIfcClassToExplore"
            />
            <button
              @click="_dispatch('getIfcAssets', bucketId, sIfcClassToExplore)"
              class="button-text-area"
              style="margin-top: 3px"
              :style="
                bucketId === '' || ifcClassToExplore === ''
                  ? 'pointer-events: none; opacity: 0.5'
                  : ''
              "
              v-text="'Load related ifc-class Geometries'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; height: 200px"
              size="60"
              v-model="sRenderExpressIds"
              multiple
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(asset, index) of ifcClassExpressIds"
                :key="index"
                :value="asset"
                v-text="`${index} - ${asset.model}`"
              />
            </select>
            <button
              @click="_dispatch('renderAssets', bucketId, sRenderExpressIds)"
              style="margin-top: 3px"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Show at the viewer'"
            />
            <button
              @click="_dispatch('toggleVisibleItems', sRenderExpressIds)"
              style="margin-top: 3px"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Toggle visible items'"
            />
            <hr />
            <p
              style="color: #2b2e33; font-size: 1rem"
              v-text="'Context ifc-classes'"
            />
            <button
              @click="_dispatch('getContextList', bucketId)"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Load context ifc classes'"
            />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              multiple
              v-model="sContextIfcClasses"
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d; user-select: none"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(ifcClass, index) of contextIfcClasses"
                :key="index"
                v-text="ifcClass"
              />
            </select>
            <button
              @click="
                _dispatch('renderBucketContexts', bucketId, sContextIfcClasses)
              "
              style="margin-top: 3px"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              class="button-text-area"
              v-text="'Render bucket contexts'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'3 Render bucket Models'" />
            <input
              type="checkbox"
              class="label-text"
              id="includeContext"
              :checked="sIncludeContext"
              @change="_sIncludeContext"
            />
            <label
              class="label-text"
              for="includeContext"
              v-text="'Include context IfcClasses'"
            />
            <button
              @click="
                _dispatch(
                  'renderBucketModels',
                  bucketId,
                  sIncludeContext,
                  selectedModels
                )
              "
              class="button-text-area"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="'Render models'"
            />
            <p
              style="font-size: 0.75rem"
              :style="`color: ${bucketId === '' ? '#a63d3d' : '#474747'}`"
              v-text="
                bucketId === ''
                  ? 'Please select a bucket at step #2'
                  : `Selected bucket: ${bucketId}`
              "
            />
            <hr />
            <p style="font-size: 14px" v-text="'Bucket Models'" />
            <select
              class="data-list"
              style="margin-top: 3px; position: relative"
              size="20"
              v-model="selectedModels"
              multiple
            >
              <option
                v-for="(bucketModel, index) of bucketModels"
                :key="index"
                :value="bucketModel"
                v-text="bucketModel"
              />
            </select>
          </details>
          <details>
            <summary class="sub-summary" v-text="'4 Reset viewer'" />
            <button
              @click="_dispatch('resetViewer')"
              class="button-text-area"
              v-text="'Clear'"
            />
          </details>
        </details>
        <details>
          <summary
            style="font-weight: bold; background: rgba(126, 92, 48, 0.33)"
            v-text="'Get measures and points'"
          />
          <details>
            <summary class="sub-summary" v-text="'1 Get point'" />
            <input
              type="checkbox"
              class="label-text"
              id="traceLines"
              :checked="sTraceLines"
              @change="_sTraceLines"
            />
            <label class="label-text" for="traceLines" v-text="'Trace lines'" />
            <button
              @click="_dispatch('setPointMode', sTraceLines)"
              class="button-text-area"
              v-text="'Get point'"
            />
            <pre
              v-if="currentPoint"
              style="
                font-size: 0.75rem;
                color: #808080;
                margin: auto;
                width: 50%;
                text-align: center;
                padding: 2px;
                border: 1px solid #808080;
              "
              v-text="`Current point coords: ${formatPoint(currentPoint)}`"
            />
            <hr />
            <p
              style="font-size: 0.75rem"
              v-text="'Stored points (click at viewer)'"
            />
            <div style="max-height: 10rem; overflow-y: auto">
              <p
                v-for="(point, index) of points"
                :key="index"
                style="font-size: 0.75rem; color: #808080"
                v-text="`#${index + 1} ${formatPoint(point)}`"
              />
              <hr />
              <p
                v-for="(distance, index) of distances"
                :key="`l-${index}`"
                style="font-size: 0.75rem; color: #808080"
                v-text="`Dist #${index + 1} to #${index + 2}: ${distance}`"
              />
              <p
                style="font-size: 0.75rem; color: #808080"
                v-text="`Perimeter: ${perimeter}`"
              />
            </div>
          </details>
          <details>
            <summary class="sub-summary" v-text="'2 Get measures'" />
            <p
              style="font-size: 0.75rem; color: #808080"
              v-text="'Distances'"
            />
            <button
              @click="_dispatch('setSnapMode')"
              class="button-text-area"
              v-text="'Select points'"
            />
            <hr />
            <p
              style="font-size: 0.75rem; color: #808080"
              v-text="'Perimeter / Area'"
            />
            <button
              @click="_dispatch('setAreaMode')"
              class="button-text-area"
              v-text="'Perimeter / Area calculator'"
            />
            <hr />
            <p style="font-size: 0.75rem; color: #808080" v-text="'Angles'" />
            <button
              @click="_dispatch('setAngleMode')"
              class="button-text-area"
              v-text="'Trace angles'"
            />
            <hr />
            <p style="font-size: 0.75rem; color: #808080" v-text="'Volume'" />
            <button
              @click="_dispatch('setVolumeMode')"
              class="button-text-area"
              v-text="'Pick for geometry volume'"
            />
            <hr />
            <button
              @click="_dispatch('toggleShowMeasures')"
              class="button-text-area"
              v-text="'Toggle show measures'"
            />
            <button
              @click="_dispatch('deleteMeasures')"
              class="button-text-area"
              v-text="'Delete all measures'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'3 Zoom modes'" />
            <p style="font-size: 0.75rem" v-text="'Interactive methods'" />
            <button
              @click="_dispatch('setZoomWindow')"
              class="button-text-area"
              v-text="'Zoom window'"
            />
            <button
              @click="_dispatch('setZoomExtended')"
              class="button-text-area"
              v-text="'Zoom extended'"
            />
            <button
              @click="_dispatch('setZoomToObject')"
              class="button-text-area"
              v-text="'Zoom to asset'"
            />
            <hr style="margin: 8px" />
            <p style="font-size: 0.75rem" v-text="'Asset-based methods'" />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              multiple
              v-model="sRenderExpressIds"
            >
              <option
                v-for="(item, index) of renderedExpressIds"
                :key="index"
                :value="item"
                v-text="item"
              />
            </select>
            <button
              @click="_dispatch('setZoomSelected', sRenderExpressIds)"
              class="button-text-area"
              v-text="'Zoom selected'"
            />
            <hr style="margin: 8px" />
            <p style="font-size: 0.75rem" v-text="'Point method'" />
            <div style="display: flex; flex-direction: column">
              <label class="label-text" for="radius" v-text="'Zoom radius'" />
              <input
                id="radius"
                type="number"
                style="max-width: 100px"
                step="1"
                :value="zoomPoint.radius"
                @input="zoomPoint.radius = parseFloat($event.target.value)"
              />
            </div>
            <label
              class="label-text"
              for="point"
              v-text="'Point coordinates (x, y, z)'"
            />
            <div id="point" style="display: flex">
              <input
                style="max-width: 100px; margin-right: 2rem"
                type="number"
                :value="zoomPoint.x"
                @input="zoomPoint.x = parseFloat($event.target.value)"
              />
              <input
                style="max-width: 100px; margin-right: 2rem"
                type="number"
                :value="zoomPoint.y"
                @input="zoomPoint.y = parseFloat($event.target.value)"
              />
              <input
                style="max-width: 100px"
                type="number"
                :value="zoomPoint.z"
                @input="zoomPoint.z = parseFloat($event.target.value)"
              />
            </div>
            <button
              @click="_dispatch('zoomCenter', zoomPoint)"
              class="button-text-area"
              v-text="'Zoom center (point)'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'4 Mass tools'" />
            <button
              @click="_dispatch('setVolumeTraceMode')"
              class="button-text-area"
              v-text="'Trace IFC-Mass from clicks'"
            />
            <hr />
            <input
              type="text"
              class="text-area"
              placeholder="Mass group name"
              v-model="massName"
            />
            <button
              @click="_dispatch('saveMasses', bucketId, massName)"
              class="button-text-area"
              v-text="'Save IFC-Masses'"
            />
            <hr />
            <button
              @click="_dispatch('listBucketMasses', bucketId)"
              class="button-text-area"
              v-text="'List bucket IFC-Masses'"
            />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              v-model="selectedMass"
            >
              <option
                v-for="(mass, index) of masses"
                :key="index"
                :value="mass"
                v-text="mass.massName"
              />
            </select>
            <button
              @click="_dispatch('loadIfcMass', selectedMass)"
              class="button-text-area"
              v-text="'Render IFC-Mass'"
            />
          </details>
        </details>
        <details>
          <summary
            style="font-weight: bold; background: rgba(118, 55, 130, 0.34)"
            v-text="'Geometries appearance'"
          />
          <details>
            <summary class="sub-summary" v-text="'1 Transparency & Ghosting'" />
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
              "
            >
              <p
                style="font-size: 0.75rem"
                v-text="`Transparency rate: ${sTransparencyRate * 10}%`"
              />
              <input
                v-model="sTransparencyRate"
                type="range"
                min="0"
                max="10"
                step="1"
              />
            </div>
            <button
              @click="_dispatch('setTransparentPicker', sTransparencyRate)"
              class="button-text-area"
              v-text="'Click at viewer for transparent items'"
            />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              multiple
              v-model="sRenderExpressIds"
            >
              <option
                v-for="(item, index) of renderedExpressIds"
                :key="index"
                :value="item"
                v-text="item"
              />
            </select>
            <button
              @click="
                _dispatch(
                  'setTransparentAssets',
                  sRenderExpressIds,
                  sTransparencyRate
                )
              "
              class="button-text-area"
              v-text="'Click for transparent selected express-ids'"
            />
            <button
              @click="_dispatch('toggleGhostMode')"
              class="button-text-area"
              v-text="'Toggle Ghost Mode'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; position: relative"
              name="IfcCategories"
              size="20"
              v-model="sIfcClassToExplore"
            >
              <option
                v-if="bucketId === ''"
                style="color: #a63d3d"
                v-text="'Please, select a bucket-id at #2'"
              />
              <option
                v-for="(ifcClass, index) of ifcClasses"
                :key="index"
                :value="ifcClass"
                v-text="ifcClass"
              />
            </select>
            <button
              @click="_dispatch('getIfcClasses', bucketId)"
              class="button-text-area"
              :style="
                bucketId === '' ? 'pointer-events: none; opacity: 0.5' : ''
              "
              v-text="'Load IFC classes'"
            />
            <button
              @click="
                _dispatch(
                  'setTransparentIfcClass',
                  bucketId,
                  sIfcClassToExplore,
                  sTransparencyRate
                )
              "
              class="button-text-area"
              v-text="'Transparent selected IFC class'"
            />
            <button
              @click="
                _dispatch('setGhostingIfcClass', bucketId, sIfcClassToExplore)
              "
              class="button-text-area"
              v-text="'Ghost mode at IFC class'"
            />
            <button
              @click="
                _dispatch(
                  'recoverGhostingIfcClass',
                  bucketId,
                  sIfcClassToExplore
                )
              "
              class="button-text-area"
              v-text="'Recover ghost IFC classes'"
            />
            <hr />
            <div style="display: flex; flex-direction: column; margin-top: 8px">
              <div style="display: flex; align-items: center">
                <div
                  :style="`background-color: ${ghostColor}; width: 1rem; height: 1rem`"
                />
                <p class="label-text" v-text="ghostColor" />
                <input
                  style="margin-left: 8px"
                  type="text"
                  v-model="sGhostColor"
                />
              </div>
            </div>
            <!--            <p-->
            <!--              style="font-size: 14px; margin-top: 8px"-->
            <!--              v-text="'Ambient settings'"-->
            <!--            />-->
            <!--            <input-->
            <!--              type="checkbox"-->
            <!--              id="ghosting"-->
            <!--              class="label-text"-->
            <!--              :checked="sShowGhosting"-->
            <!--              @change="_showGhosting"-->
            <!--            />-->
            <!--            <label-->
            <!--              class="label-text"-->
            <!--              for="ghosting"-->
            <!--              v-text="'Show ambient at scene'"-->
            <!--            />-->
            <!--            <div-->
            <!--              v-for="(inp, key) of saoInputs"-->
            <!--              :key="key"-->
            <!--              style="margin-top: 0.5rem; display: flex; align-items: center"-->
            <!--              :style="showGhosting ? '' : 'opacity: 0.2; pointer-events: none'"-->
            <!--            >-->
            <!--              <input-->
            <!--                type="range"-->
            <!--                :id="inp.title"-->
            <!--                :min="inp.min"-->
            <!--                :max="inp.max"-->
            <!--                :step="inp.step"-->
            <!--                :value="sao[inp.title]"-->
            <!--                @change="getSaoValue($event, inp.title)"-->
            <!--              />-->
            <!--              <label-->
            <!--                class="label-text"-->
            <!--                for="inp.title"-->
            <!--                v-text="`${inp.title} ${sao[inp.title]}`"-->
            <!--              />-->
            <!--            </div>-->
          </details>
          <details>
            <summary class="sub-summary" v-text="'2 Highlight & Selection'" />
            <button
              @click="_dispatch('togglePickingMode')"
              class="button-text-area"
              v-text="'Pick geometries clicking at viewer'"
            />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              multiple
              v-model="sRenderExpressIds"
            >
              <option
                v-for="(item, index) of renderedExpressIds"
                :key="index"
                :value="item"
                v-text="item.expressId"
              />
            </select>
            <button
              @click="_dispatch('pickSelectedExpressIds', sRenderExpressIds)"
              class="button-text-area"
              v-text="'Pick selected express-ids'"
            />
            <button
              @click="_dispatch('isolateSelectedExpressIds', sRenderExpressIds)"
              class="button-text-area"
              v-text="'Isolate selected express-ids'"
            />
            <button
              @click="_dispatch('deactivateIsolate')"
              class="button-text-area"
              v-text="'Deactivate isolate'"
            />
          </details>
        </details>
        <details>
          <summary
            style="font-weight: bold; background: rgba(123, 57, 75, 0.34)"
            v-text="'Viewer tools'"
          />
          <details>
            <summary class="sub-summary" v-text="'1 Cube-map'" />
            <button
              @click="_dispatch('toggleShowCubeMap', false)"
              class="button-text-area"
              v-text="'Deactivate show Cube Map'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'2 Section planes'" />
            <button
              @click="_dispatch('toggleClippingPlanes')"
              class="button-text-area"
              v-text="
                'Enable section-planes (double click at viewer to create)'
              "
            />
            <button
              @click="_dispatch('createClippingPlane')"
              class="button-text-area"
              v-text="'Create section plane at height 0'"
            />
            <input
              type="text"
              class="text-area"
              placeholder="Section plane name"
              v-model="sClippingPlaneName"
            />
            <button
              @click="
                _dispatch('createSectionPlan', bucketId, sClippingPlaneName)
              "
              class="button-text-area"
              v-text="'Store section plane'"
            />
            <hr />
            <button
              @click="_dispatch('getSectionPlanes', bucketId)"
              class="button-text-area"
              v-text="'List bucket section planes'"
            />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              v-model="sSelectedSection"
            >
              <option
                v-for="(section, index) of sections"
                :key="index"
                :value="section"
                v-text="section.sectionPlaneName"
              />
            </select>
            <button
              @click="_dispatch('loadSectionPlane', sSelectedSection)"
              class="button-text-area"
              v-text="'Load section-plane'"
            />
            <hr />
            <button
              @click="_dispatch('toggleShowClippingPlanes')"
              class="button-text-area"
              v-text="'Toggle show section-planes gizmo'"
            />
            <button
              @click="_dispatch('toggleDeactivateClippingPlanes')"
              class="button-text-area"
              v-text="'Toggle deactivate section-planes'"
            />
            <button
              @click="_dispatch('deleteClippingPlanes')"
              class="button-text-area"
              v-text="'Delete all section-planes'"
            />
            <hr />
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
              "
            >
              <input
                v-model="sClippingPlanesSize"
                type="range"
                id="clippingPlanesSize"
                min="1"
                max="1000"
                step="1"
              />
              <label
                class="label-text"
                for="clippingPlanesSize"
                v-text="`Section-planes size: ${sClippingPlanesSize}`"
              />
            </div>
          </details>
          <details>
            <summary class="sub-summary" v-text="'3 Save and Load Views'" />
            <button
              @click="_dispatch('listIfcViews', bucketId)"
              class="button-text-area"
              v-text="'List stored Views'"
            />
            <select
              class="data-list"
              style="margin-top: 3px"
              size="20"
              v-model="sSelectedView"
            >
              <option
                v-for="(view, index) of ifcViews"
                :key="index"
                :value="view"
                v-text="view.viewName"
              />
            </select>
            <button
              @click="_dispatch('loadIfcView', sSelectedView)"
              class="button-text-area"
              v-text="'Load ifc view'"
            />
            <hr style="margin: 8px" />
            <input
              type="text"
              class="text-area"
              @input="_viewName($event.target.value)"
            />
            <button
              @click="_dispatch('saveIfcView', bucketId, viewName)"
              class="button-text-area"
              v-text="'Save View'"
            />
          </details>
          <!-- @beta features WIP -->
          <details>
            <summary class="sub-summary" v-text="'4 Camera features'" />
            <button
              @click="_dispatch('setCameraPlan')"
              class="button-text-area"
              v-text="'Camera Plan mode'"
            />
            <button
              @click="_dispatch('setFreeCamera')"
              class="button-text-area"
              v-text="'Camera Free mode'"
            />
            <button
              @click="_dispatch('setFirstPersonCamera', true)"
              class="button-text-area"
              v-text="'Camera First Person mode'"
            />
          </details>
          <details>
            <summary class="sub-summary" v-text="'5 Levels features'" />
            <!--            <button-->
            <!--              @click="_dispatch('listModelsLevels', bucketId)"-->
            <!--              class="button-text-area"-->
            <!--              v-text="'Get IFC-Levels from processed Models'"-->
            <!--            />-->
            <!--            <select-->
            <!--              class="data-list"-->
            <!--              style="margin-top: 3px"-->
            <!--              size="20"-->
            <!--              v-model="sSelectedLevel"-->
            <!--            >-->
            <!--              <option-->
            <!--                v-for="(ifcLevel, index) of ifcModelsLevels"-->
            <!--                :key="index"-->
            <!--                :value="ifcLevel"-->
            <!--                v-text="ifcLevel.levelName"-->
            <!--              />-->
            <!--            </select>-->
            <!--            <button-->
            <!--              @click="-->
            <!--                _dispatch('exportLevelToBucket', bucketId, sSelectedLevel)-->
            <!--              "-->
            <!--              class="button-text-area"-->
            <!--              v-text="'Export in-file level to Bucket'"-->
            <!--            />-->
            <!--            <hr />-->
            <button
              @click="_dispatch('listBucketLevels', bucketId)"
              class="button-text-area"
              v-text="'List custom bucket levels'"
            />
            <select
              class="data-list"
              style="margin-top: 3px; margin-bottom: 12px"
              size="20"
              v-model="sSelectedLevel"
            >
              <option
                v-for="(ifcLevel, index) of ifcBucketLevels"
                :key="index"
                :value="ifcLevel"
                v-text="ifcLevel.levelName"
              />
            </select>
            <button
              @click="_dispatch('loadIfcLevel', sSelectedLevel)"
              class="button-text-area"
              v-text="'Load IFC-Level'"
            />
            <hr />
            <input
              type="text"
              class="text-area"
              placeholder="Level name"
              v-model="levelName"
            />
            <label
              class="label-text"
              for="base-height"
              v-text="'Base height'"
            />
            <input
              id="base-height"
              type="number"
              class="text-area"
              placeholder="Base height"
              v-model="baseHeight"
            />
            <label
              class="label-text"
              for="elevation-height"
              v-text="'Elevation height'"
            />
            <input
              id="elevation-height"
              type="number"
              class="text-area"
              placeholder="Elevation height"
              v-model="height"
            />
            <button
              @click="
                _dispatch(
                  'storeLevel',
                  bucketId,
                  levelName,
                  baseHeight,
                  height,
                  ''
                )
              "
              class="button-text-area"
              v-text="'Store level'"
            />
          </details>
          <!--          <details>-->
          <!--            <summary class="sub-summary" v-text="'6 Sub-viewer'" />-->
          <!--            <button-->
          <!--              @click="_dispatch('addSplitScreen', 1)"-->
          <!--              class="button-text-area"-->
          <!--              v-text="'Add split-screen plane'"-->
          <!--            />-->
          <!--            <button-->
          <!--              @click="_dispatch('addSplitScreen', 2)"-->
          <!--              class="button-text-area"-->
          <!--              v-text="'Add floating-panel plane'"-->
          <!--            />-->
          <!--          </details>-->
        </details>
      </nav>
      <div class="viewer-ifc">
        <Viewer />
        <StructurePanel
          v-if="structure"
          :item="structure"
          class="structure-wrapper"
        />
      </div>
    </div>
    <footer class="footer">
      <a @click="openDocs(false)" v-text="'Frontend documentation'" />
      <a @click="openDocs(true)" v-text="'Server documentation'" />
    </footer>
  </div>
</template>

<script>
import { getType, openDocs } from '../helpers'

import Viewer from '../components/Viewer/Viewer.vue'
import StructurePanel from '@/components/Viewer/StructurePanel.vue'
import PropertiesPanel from '@/components/Viewer/PropertiesPanel.vue'

import store from '../store/index'
import computed from '../store/getters'
import saoInputs from '@/config/sao-inputs'

export default {
  name: 'Home',

  components: {
    Viewer,
    StructurePanel,
    PropertiesPanel
  },

  computed,

  data() {
    return {
      baseHeight: 0,
      height: 0,
      levelName: '',
      massName: '',
      perimeter: 0,
      resetBucketId: true,
      saoInputs,
      sClippingPlaneName: '',
      sClippingPlanesSize: 100,
      sClockwise: true,
      sContextIfcClasses: [],
      selectedModels: [],
      showComponent: false,
      sGhostColor: 0xfccfcc,
      sIfcClassToExplore: '',
      sIncludeContext: true,
      sIsContextIfcClasses: true,
      sObjectsByIfcClass: [],
      sSelectedLevel: null,
      sSelectedSection: null,
      assetsToRestore: [],
      selectedMass: null,
      sRenderExpressIds: [],
      sSelectedView: null,
      sShowGhosting: true,
      sTraceLines: false,
      sTransparencyRate: 10,
      sShowStructure: true
    }
  },

  methods: {
    _bucketId(bucketId) {
      store.dispatch('setBucketId', bucketId)
      this._dispatch('getBucketModels', bucketId)
    },

    _ifcPath(path) {
      store.dispatch('setIfcPath', path)
    },

    _isContextIfcClasses() {
      this.sIsContextIfcClasses = !this.sIsContextIfcClasses

      store.dispatch('setIsContextIfcClasses', this.sIsContextIfcClasses)
    },

    _modelName(modelName) {
      store.dispatch('setModelName', modelName)
    },

    _ownBucketId(bucketId) {
      store.dispatch('setOwnBucketId', bucketId)
    },

    _sClockwise() {
      this.sClockwise = !this.sClockwise

      store.dispatch('setClockwise', this.sClockwise)
    },

    // _selectedIfcClass(ifcClass) {
    //   store.dispatch('setAttributes', ifcClass)
    // },
    //
    // _showGhosting() {
    //   store.dispatch('setShowGhosting', !this.showGhosting)
    // },

    _showStructure() {
      this.sShowStructure = !this.sShowStructure

      store.dispatch('setShowStructure', this.sShowStructure)
    },

    _sIncludeContext() {
      this.sIncludeContext = !this.sIncludeContext

      store.dispatch('setIncludeContext', this.sIncludeContext)
    },

    _sTraceLines() {
      this.sTraceLines = !this.sTraceLines

      store.dispatch('setTraceLines', this.sTraceLines)
    },

    _viewName(name) {
      store.dispatch('setViewName', name)
    },

    _dispatch(key, ...params) {
      store.dispatch('setSubmit', {
        [key]: params.length ? params : []
      })
    },

    getType(type) {
      return getType(type)
    },

    showBrowser() {
      this._dispatch('showBrowser')
    },

    openDocs(isDocsServer = false) {
      openDocs(isDocsServer)
    },

    formatPoint(point) {
      return `\nx: ${point.x}\ny: ${point.y}\nz: ${point.z}`
    }

    // getSaoValue({ target: { value } }, key) {
    //   store.dispatch('setSao', {
    //     ...store.getters.sao,
    //     [key]: parseFloat(value)
    //   })
    // }
  },

  watch: {
    distances() {
      let perimeter = 0

      for (const distance of this.distances) {
        perimeter += distance
      }

      this.perimeter = perimeter
    },

    sClippingPlanesSize() {
      store.dispatch('setClippingPlanesSize', this.sClippingPlanesSize)
    },

    sContextIfcClasses() {
      store.dispatch('setSelectedContextIfcClasses', this.sContextIfcClasses)
    },

    sGhostColor() {
      store.dispatch('setGhostColor', this.sGhostColor)
    },

    sIfcClassToExplore() {
      store.dispatch('setIfcClassToExplore', this.sIfcClassToExplore)
    },

    sObjectsByIfcClass() {
      store.dispatch('setSelectedExpressIds', this.sObjectsByIfcClass)
    },

    assetsToRestore() {
      store.dispatch('setSelectedAssetsToRestore', this.assetsToRestore)
    },

    sRenderExpressIds() {
      store.dispatch('setSelectedRenderExpressIds', this.sRenderExpressIds)
    },

    sSelectedView() {
      store.dispatch('setSelectedView', this.sSelectedView)
    },

    sTransparencyRate() {
      store.dispatch('setTransparencyRate', parseInt(this.sTransparencyRate))
    }
  }
}
</script>
