import { AmbientLight, DirectionalLight } from 'three'
import settings from '@/config/scene'

/** Lights to scene */
export default scene => {
  const directionalLight = new DirectionalLight()
  directionalLight.position.set(5, 10, 3)
  directionalLight.intensity = settings.global.directionalLightIntensity
  directionalLight.castShadow = true

  scene.add(directionalLight)

  const ambientLight = new AmbientLight()
  ambientLight.intensity = settings.global.ambientLightIntensity
  ambientLight.castShadow = true

  scene.add(ambientLight)
}
