import {
  AngleMeasurement,
  AreaMeasurement,
  EdgesClipper,
  LengthMeasurement,
  PrismMeasurement
} from 'openbim-components'
import store from '@/store'
import { CubeMap, EdgesPlane } from 'openbim-components'

export default viewer => {
  /** @group Viewer-tools */

  /** @satisfies CutPlanes function tool */
  const clipperTool = new EdgesClipper(viewer, EdgesPlane)

  clipperTool.material.opacity = 0.05
  clipperTool.material.depthTest = false
  clipperTool.material.depthWrite = false

  /** @satisfies DisplayDist function tool */
  const dimensionsTool = new LengthMeasurement(viewer, { color: '#39b94a' })

  /** @satisfies DisplayPerimeter & DisplayArea tool */
  const areaTool = new AreaMeasurement(viewer, { color: '#2244ff' })

  /** @satisfies DisplayAngle tool */
  const angleTool = new AngleMeasurement(viewer, { color: '#7f53d5' })

  const prismTool = new PrismMeasurement(viewer, { color: '#33ffaa' })

  store.dispatch('setTools', {
    clipperTool,
    angleTool,
    areaTool,
    dimensionsTool,
    prismTool
  })

  if (store.getters.tools.cubeMap === undefined) {
    const cubeMap = new CubeMap(viewer)
    cubeMap.setPosition('top-right')
    viewer.tools.add('navCube', cubeMap)

    store.dispatch('setTools', {
      ...store.getters.tools,
      cubeMap
    })
  }
}
