import getMousePosition from '@/controllers/mountZoomWindow/get-mouse-position'
import { createZoomWindowTarget } from '@/helpers'

/** Draws rectangle according mouse position */
export default (
  event,
  canvas,
  context,
  center,
  initialX,
  initialY,
  isDrawing,
  isZoom = true
) => {
  if (!isDrawing) return

  context.clearRect(0, 0, canvas.width, canvas.height)

  let { x, y } = getMousePosition(canvas, event)

  if (!isZoom && !initialX && !initialY) {
    initialX = x - 1
    initialY = y - 1
  }

  context.strokeRect(initialX, initialY, x - initialX, y - initialY)
  context.fillRect(initialX, initialY, x - initialX, y - initialY)

  center = {
    x: (x - initialX) / 2 + initialX,
    y: (y - initialY) / 2 + initialY
  }

  if (x > initialX) {
    context.strokeStyle = '#cc005c'
    context.fillStyle = "rgba(206,126,132,0.5)"
  } else {
    context.strokeStyle = '#99cc00'
    context.fillStyle = "rgba(101,164,93,0.5)"
  }

  /** Graphic decoration for rectangle center-target */
  if (isZoom) createZoomWindowTarget(context, center)
  else return { initialX, initialY }
}
