import store from '@/store'

/**
 * Method to format file payload to send to server
 * @param endpointPath path for API-server
 * @param body payload data for query
 * @param isGeometries establishes if requested resource is a json or a .frag file
 * */
export default async (endpointPath, body, isGeometries = false) => {
  try {
    const base = store.getters.serverUrl

    let url = `${base}${endpointPath}`
    let url2 = `${base}buckets/write-file`
    let url3 = `${base}buckets/write-binaries`

    const { bucket, modelTitle, file, geometriesBinaries } = body

    const xhr = new XMLHttpRequest()

    const formData = new FormData()

    formData.append('bucket', bucket)

    formData.append('modelTitle', modelTitle)

    formData.append(
      'fileTitle',
      isGeometries ? 'geometries-binaries.frag' : file.name
    )

    xhr.open('POST', url)

    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const xhr2 = new XMLHttpRequest()

        if (isGeometries) {
          formData.append(
            'geometriesBinaries',
            new Blob([geometriesBinaries], { type: 'application/octet-stream' })
          )

          await fetch(url3, {
            method: 'POST',
            body: formData
          })
        } else {
          formData.append('file', file)
          xhr2.open('POST', url2)
          xhr2.send(formData)
        }
      }
    }

    return xhr.send(formData)
  } catch (error) {
    console.error('error', error)
  }
}
