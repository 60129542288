import app from '@/main'
import store from '@/store'

/** Highlighter handling */
export default (event, fragments, highlighter, isFirstlyInvoked) => {
  const { raycaster } = store.getters.viewer2D

  const cast = raycaster.castRay(fragments.meshes)

  if (cast) {
    const removePrevious = !event.shiftKey
    const result = highlighter.highlight('highlight', removePrevious)

    app.$emit('express-ids-subviewer', { result, removePrevious })

    // todo: refactor when 1st invoke fix available at libs
    if (isFirstlyInvoked) {
      app.$emit('express-ids-subviewer', { result, removePrevious })
    }
  } else {
    highlighter.clear('highlight')
    highlighter.clear('preselection')

    app.$emit('express-ids-subviewer', null)
  }
}
