/** Set ZoomWindow tool */
import store from '@/store'

import {
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Quaternion,
  Vector3
} from 'three'

import { createZoomWindowTarget } from '@/helpers'

export default () => {
  const _this = store.getters

  const canvas = document.getElementById('markers')
  const context = canvas.getContext('2d')

  canvas.height = _this.viewer.renderer._renderer.domElement.height
  canvas.width = _this.viewer.renderer._renderer.domElement.width

  context.strokeStyle = '#898d92'

  let initialX, initialY, center

  let isDrawing = false

  /** For user start drawing */
  const begin = ({ offsetX, offsetY }) => {
    if (_this.zoomWindowMode) {
      isDrawing = true
      context.beginPath()

      initialX = offsetX
      initialY = offsetY
    }
  }

  canvas.addEventListener('mousedown', begin)

  /** Close zoom-rectangle */
  const endDrawing = () => {
    if (_this.zoomWindowMode) {
      context.clearRect(0, 0, canvas.width, canvas.height)
      isDrawing = false

      store.dispatch('setZoomWindowMode', false)

      const distanceArr = [_this.viewer.camera.controls.distance]

      if (!_this.planeWasAdded) {
        const material = new MeshBasicMaterial({
          transparent: true,
          opacity: 0,
          color: 0xcfccfc
        })

        const geometry = new PlaneGeometry(10000, 10000, 10, 10)

        store.dispatch('setPlane', new Mesh(geometry, material))

        _this.scene.add(_this.plane)

        store.dispatch('setPlaneWasAdded', true)
      }

      /** Sets a plane perpendicular to camera to calculate zoom-window
       *  for points with no geometries.  */
      const updatePlane = () => {
        const quat = new Quaternion()
        const normal = new Vector3()

        _this.viewer.camera.activeCamera.getWorldQuaternion(quat)

        normal.set(0, 0, 1).applyQuaternion(quat)
        _this.plane.visible = true
        _this.plane.lookAt(_this.plane.position.clone().add(normal))

        /** Calculate rates of viewer DOM element & user zoom-window */
        setTimeout(() => {
          let xOffset,
            yOffset,
            isWidthGreater,
            diagonalWrapperRate,
            rate,
            windowWidth,
            windowHeight

          const { width, height } = _this.viewer.renderer._renderer.domElement

          diagonalWrapperRate =
            width >= height ? height / width : width / height

          if (initialX < center.x) {
            xOffset = center.x - initialX
          } else {
            xOffset = initialX - center.x
          }

          if (initialY < center.y) {
            yOffset = center.y - initialY
          } else {
            yOffset = initialY - center.y
          }

          windowWidth = xOffset * 2
          windowHeight = yOffset * 2

          isWidthGreater = windowWidth >= windowHeight

          if (isWidthGreater) {
            const derivedHeight = windowWidth * diagonalWrapperRate
            rate = derivedHeight / height
          } else {
            const derivedWidth = windowHeight * diagonalWrapperRate
            rate = derivedWidth / width
          }

          const distance = rate * distanceArr[0]

          _this.viewer.raycaster.mouse.offset = {
            x: initialX > center.x ? xOffset : -xOffset,
            y: initialY > center.y ? yOffset : -yOffset
          }

          /** Ray emission to find colliding clicks to geometries / plane  */
          const cast = _this.viewer.raycaster.castRay([
            ..._this.scene.children,
            _this.plane
          ])

          _this.viewer.camera.controls.setTarget(
            cast.point.x,
            cast.point.y,
            cast.point.z,
            false
          )

          _this.viewer.camera.activeCamera.rotation.set(0, 0, 0)

          _this.viewer.camera.controls.distance =
            distance > 0 ? distance : -distance

          _this.plane.visible = false
        }, 50)
      }

      updatePlane()
    }
  }

  canvas.addEventListener('mouseup', endDrawing)

  const getMousePosition = (canvas, evt) => {
    let rect = canvas.getBoundingClientRect(),
      scaleX = canvas.width / rect.width,
      scaleY = canvas.height / rect.height

    return {
      x: (evt.clientX - rect.left) * scaleX,
      y: (evt.clientY - rect.top) * scaleY
    }
  }

  /** Draws rectangle according mouse position */
  const draw = e => {
    if (_this.zoomWindowMode) {
      if (!isDrawing) return

      context.clearRect(0, 0, canvas.width, canvas.height)

      let { x, y } = getMousePosition(canvas, e)

      context.strokeRect(initialX, initialY, x - initialX, y - initialY)

      center = {
        x: (x - initialX) / 2 + initialX,
        y: (y - initialY) / 2 + initialY
      }

      /** Graphic decoration for rectangle center-target */
      createZoomWindowTarget(context, center)
    }
  }

  canvas.addEventListener('mousemove', draw)
}
