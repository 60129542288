import store from '@/store'

/** Receives an asset, containing glb & coords json file needed
 * to reach render properly
 * @param file fragment-model parsed to array of bytes for viewer rendering
 * @returns Promise<Fragment> */
export default async file => {
  try {
    const { fragments } = store.getters

    return await fragments.load(Object.values(file)[0])
  } catch (error) {
    console.error('error', error)
  }
}
