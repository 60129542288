import store from '@/store'

export default async () => {
  const {
    markVolumeHeight,
    tools: { prismTool }
  } = store.getters

  if (!markVolumeHeight) {
    prismTool.create()
  } else {
    const prisms = prismTool.get()

    await store.dispatch('setMarkVolumeHeight', false)
    await store.dispatch('setVolumeTraceMode', false)
    await store.dispatch('setMasses', prisms)

    return prisms
  }
}
