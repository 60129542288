<template>
  <div v-if="total" class="loading-bar">
    <p
      style="position: absolute; margin-top: -1.25rem; font-size: 0.75rem"
      v-text="loadingText"
    />
    <div
      :style="`width: ${rate}%`"
      class="loading-progress"
      v-text="`${rate}%`"
    />
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: 'LoadingBar',

  computed: {
    current() {
      return store.getters.loadingCurrent
    },

    loadingText() {
      return store.getters.loadingText
    },

    total() {
      return store.getters.loadingTotal
    },

    rate() {
      return Math.floor((this.current / this.total) * 100)
    }
  },

  watch: {
    current() {
      if (this.current === this.total) {
        store.dispatch('setLoadingTotal', 0)
      }
    }
  }
}
</script>
