import initViewer2D from '@/controllers/mount2DViewer/initViewer2D'
import store from '@/store'
import {
  EdgesClipper,
  EdgesPlane,
  FragmentEdges,
  FragmentManager,
  ScreenCuller
} from 'openbim-components'
import { Vector3 } from 'three'
import setHighlight from '@/controllers/mountViewer/set-highlight'
import highlightAt2DViewer from '@/controllers/mount2DViewer/highlight-at-2D-viewer'

/** Shows the split-viewer 2D at Levels feature */
export default async () => {
  const wrapper = document.getElementById('viewer-2d-split')

  const { viewer2D } = await initViewer2D(wrapper)

  const { fragments, ifcLevel } = store.getters

  const culler = new ScreenCuller(viewer2D)
  const edges = new FragmentEdges(viewer2D, culler)

  // Based on existing rendered geometries, we load them again at the new viewer
  const binaries = fragments.export(fragments.groups[0])

  const newFragments = new FragmentManager(viewer2D)

  newFragments.load(binaries)

  const { baseHeight, height } = ifcLevel

  for (const item of Object.values(newFragments.list)) {
    culler.add(item.mesh)
    edges.add(item)
  }

  // Events
  wrapper.onwheel = () => (culler.needsUpdate = true)
  wrapper.onmouseup = () => (culler.needsUpdate = true)
  wrapper.onresize = () => (culler.needsUpdate = true)

  viewer2D.culler = culler

  await store.dispatch('setViewer2D', viewer2D)

  // Level creation at new 2D-viewer
  const clipperTool = new EdgesClipper(viewer2D, EdgesPlane)

  clipperTool.enabled = true

  clipperTool.createFromNormalAndCoplanarPoint(
    new Vector3(0, 1, 0),
    new Vector3(0, baseHeight, 0)
  )

  clipperTool.createFromNormalAndCoplanarPoint(
    new Vector3(0, -1, 0),
    new Vector3(0, baseHeight + height, 0)
  )

  clipperTool.visible = false

  await store.dispatch('setTools', {
    ...store.getters.tools,
    clipperTool2: clipperTool
  })

  const highlighter = setHighlight(viewer2D, newFragments, true)

  let isFirstlyInvoked = true

  // Highlighting
  wrapper.onclick = event => {
    highlightAt2DViewer(event, newFragments, highlighter, isFirstlyInvoked)

    if (isFirstlyInvoked) {
      isFirstlyInvoked = false
    }
  }

  document.addEventListener('highlight-express-ids', ({ detail }) => {
    const { selection, removePrevious } = detail
    highlighter.highlightByID('highlight', selection, removePrevious)
  })

  store.getters.culler.needsUpdate = true
  viewer2D.update()
  culler.needsUpdate = true

  window.dispatchEvent(new Event('resize'))
}
