import {
  Components,
  OrthoPerspectiveCamera,
  SimpleRaycaster,
  SimpleRenderer,
  SimpleScene
} from 'openbim-components'
import instanceTools from '@/controllers/mountViewer/instance-tools'

/** Initial configs for viewer
 * @param wrapper the container where the viewer will be placed
 * @returns {{viewer: Components, renderer: SimpleRenderer}} instances
 * */
export default wrapper => {
  const viewer = new Components()
  viewer.scene = new SimpleScene(viewer)

  const renderer = new SimpleRenderer(viewer, wrapper)

  viewer.renderer = renderer

  viewer.camera = new OrthoPerspectiveCamera(viewer)
  viewer.camera.controls.setLookAt(25, 2, 25, 0, 0, 0)

  viewer.raycaster = new SimpleRaycaster(viewer)
  viewer.init()

  // viewer.renderer.postproduction.enabled = true

  instanceTools(viewer)

  return { viewer, renderer }
}
