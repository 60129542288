import getResult from '@/controllers/highlight/get-result'
import highlightResult from '@/controllers/highlight/highlight-result'

export default event => {
  const removePrevious = !event.shiftKey

  const result = getResult(removePrevious)

  return highlightResult(result, removePrevious)
}
