import getPsetDefinition from '@/controllers/get-item-properties/get-pset-definition'

export default (allProperties, baseProperties, id) => {
  const expressId = parseInt(id)

  const psets = getPsetDefinition(allProperties, expressId)

  for (const pset of psets) {
    if (pset.HasProperties) {
      pset.HasProperties = pset.HasProperties.filter(h => h !== undefined).map(
        id => {
          return allProperties[id.value]
        }
      )
    }

    if (pset.Quantities) {
      pset.Quantities = pset.Quantities.filter(q => q !== undefined).map(id => {
        return allProperties[id.value]
      })
    }
  }

  baseProperties.psets = psets
}
