/**
 * Editing this file you bind directly to getters-mutations-actions
 * Just add some keys with its default value
 * */
export default {
  angleMode: false,
  areaMode: false,
  assetsGroups: {},
  attributes: null,
  bucketId: '',
  bucketModels: [],
  buckets: [],
  camera: null,
  clippingPlanesMode: false,
  clippingPlanesSize: 100,
  clockwise: true,
  contextIfcClasses: [],
  culler: null,
  currentPoint: null,
  deletedBucketObjects: [],
  distances: [],
  fpMode: false,
  fragments: null,
  fragmentsHighlighter: null,
  fragmentIfcLoader: null,
  fragmentsMap: [],
  ghostColor: '#ffc',
  ghostItems: [],
  ghostMode: false,
  hiddenExpressIds: [],
  ifcBucketLevels: null,
  ifcClasses: [],
  ifcClassExpressIds: [],
  ifcClassToExplore: '',
  ifcLevel: null,
  ifcModelsLevels: null,
  ifcPath: process.env.VUE_APP_IFC_CLOUDINARY,
  ifcViews: [],
  includeContext: true,
  isContextIfcClasses: true,
  levelsBoth: false,
  loading: false,
  loadingCurrent: 0,
  loadingText: '',
  loadingTotal: 0,
  markVolumeHeight: false,
  masses: [],
  modelName: '',
  models: [],
  modelTitle: '',
  objectProperties: null,
  ownBucketId: '',
  picker: false,
  pickingMode: false,
  plane: null,
  planeWasAdded: false,
  pointMode: false,
  points: [],
  properties: {},
  propertiesFiles: [],
  propertiesMapper: null,
  propertiesPickingMode: false,
  renderedExpressIds: [],
  sao: {
    saoBias: 0.5,
    saoBlurDepthCutoff: 0.0015,
    saoBlurRadius: 8,
    saoBlurStdDev: 4,
    saoIntensity: 0.002,
    saoScale: 30
  },
  scene: null,
  sections: [],
  selectedAssetsToRestore: [],
  selectedContextIfcClasses: [],
  selectedExpressIds: [],
  selectedRenderExpressIds: [],
  selectedView: null,
  serverUrl: process.env.VUE_APP_SERVER_URL,
  showGhosting: true,
  showMeasures: true,
  showStructure: true,
  snapMode: false,
  structure: null,
  submit: [],
  tempLine: null,
  tempMass: null,
  tools: null,
  traceLines: false,
  transparencyRate: 10,
  transparentPicker: false,
  unfinishedFiles: [],
  uploadedFile: null,
  viewer: null,
  viewer2D: null,
  viewerMode: null,
  viewName: '',
  volumeMode: false,
  volumeTraceMode: false,
  zoomObjectMode: false,
  zoomPoint: {
    x: 0,
    y: 0,
    z: 0,
    radius: 0
  },
  zoomWindowMode: false
}
