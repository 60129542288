import store from '@/store'
import getPsets from '@/controllers/get-item-properties/get-psets'

export default (id, fragmentId) => {
  const { models, properties } = store.getters

  let allProperties, itemProperties

  for (let i = 0; i < models.length; i++) {
    allProperties = Object.values(properties[i])[0]
    itemProperties = allProperties[id]

    const check = Object.values(models[i]).find(m => m === fragmentId)

    if (check) break
  }

  getPsets(allProperties, itemProperties, id)

  return itemProperties
}
