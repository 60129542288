import store from '@/store'

export default async () => {
  const { angleTool } = store.getters.tools

  const degrees = angleTool.create()

  if (degrees) {
    angleTool.enabled = false

    await store.dispatch('setAngleMode', false)

    return degrees
  }
}
