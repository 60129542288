<template>
  <div class="properties-wrapper">
    <div class="properties-headers-wrapper">
      <p
        v-for="(propsName, index) of propsNames"
        :key="index"
        @click.stop="activePropsName = propsName"
        class="properties-header"
        :style="
          propsName === activePropsName ? 'background: cornflowerblue' : ''
        "
        v-text="propsName"
      />
    </div>
    <div
      v-for="(prop, index) in activeProps"
      class="properties-row"
      :title="`Group: ${index}`"
    >
      <p v-if="index !== 'title'" v-text="index" />
      <p
        v-if="index !== 'title'"
        style="text-overflow-ellipsis: truncate; width: 120px"
        v-text="prop ? propStr(prop) : ''"
      />
    </div>
  </div>
</template>

<script>
import computed from '@/store/getters'

export default {
  name: 'PropertiesPanel',

  computed,

  data() {
    return {
      activeProps: {},
      activePropsName: '',
      props: [],
      propsNames: []
    }
  },

  methods: {
    setActiveProps() {
      this.activeProps = {}

      if (this.activePropsName === 'base-properties') {
        this.activeProps = this.props[0]
      } else {
        const index = this.props.findIndex(
          p => p.Name.value === this.activePropsName
        )

        this.activeProps = this.props[index]
      }
    },

    getBaseProperties() {
      let baseProperties = {}

      for (let p in this.objectProperties) {
        if (p !== 'psets') {
          baseProperties = {
            ...baseProperties,
            [p]: this.objectProperties[p]
          }
        }
      }

      return baseProperties
    },

    setProperties() {
      this.activeProps = {}
      this.props = [this.getBaseProperties()]
      this.propsNames = ['base-properties']

      if (this.objectProperties) {
        for (const pset of this.objectProperties.psets) {
          this.propsNames.push(pset.Name.value)
          this.props.push(pset)
        }
      }

      this.activePropsName = this.propsNames[0]

      this.setActiveProps()
    },

    propStr(prop) {
      return typeof prop === 'string'
        ? prop
        : prop['value'] !== undefined
        ? prop['value']
        : prop
    }
  },

  mounted() {
    setTimeout(() => {
      this.setProperties()
    }, 20)
  },

  watch: {
    activePropsName() {
      this.setActiveProps()
    },

    objectProperties() {
      this.setProperties()
    }
  }
}
</script>
