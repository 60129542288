import store from '@/store'
import { DoubleSide } from 'three'

export default async (file, properties, modelName, ifcAssets) => {
  const { fragments } = store.getters

  await fragments.load(Object.values(file)[0])

  for (const mesh of fragments.meshes) {
    for (const mat of mesh.material) {
      mat.side = DoubleSide
    }
  }

  const expressIds = ifcAssets.map(i => i.expressId)

  for (const l in fragments.list) {
    const {
      items,
      blocks: { fragment }
    } = fragments.list[l]

    if (items.every(i => !expressIds.includes(i))) {
      fragment.mesh.visible = false
    } else {
      for (const item of items) {
        fragment.setVisibility(expressIds.includes(item), [item])
      }
    }
  }

  const ps = Object.values(properties)[0]

  await store.dispatch('setProperties', {
    ...store.getters.properties,
    ...ps
  })
}
