import store from '@/store'

export default () => {
  const {
    angleMode,
    areaMode,
    picker,
    pickingMode,
    pointMode,
    propertiesPickingMode,
    snapMode,
    transparentPicker,
    volumeMode,
    volumeTraceMode,
    zoomObjectMode
  } = store.getters

  const modes = {
    angleMode,
    areaMode,
    picker,
    pickingMode,
    pointMode,
    propertiesPickingMode,
    snapMode,
    transparentPicker,
    volumeMode,
    volumeTraceMode,
    zoomObjectMode
  }

  let activeModeIndex = -1

  const mValues = Object.values(modes)

  for (let i = 0; i < mValues.length; i++) {
    if (mValues[i]) {
      activeModeIndex = i
      break
    }
  }

  return Object.keys(modes)[activeModeIndex]
}
