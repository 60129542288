/**
 * Used with fetcher, according to received array params, it returns
 * formatted payload
 * */
export default params => {
  let paramsArr = []

  // Different cases according type of every param received
  for (const param of params) {
    if (typeof param === 'string') {
      paramsArr.push(`'${param}'`)
    } else if (typeof param === 'object') {
      paramsArr.push(JSON.stringify(param))
    } else {
      paramsArr.push(param)
    }
  }

  return paramsArr.join(',')
}
