import { SimpleDimensionLine } from 'openbim-components'
import store from '@/store'
import { LineDashedMaterial } from 'three'

export default (start, end) => {
  return new SimpleDimensionLine(store.getters.viewer, {
    start,
    end,
    lineMaterial: lineMaterial(),
    endpointElement: endpointElement()
  })
}

const endpointElement = () => {
  // element.className = 'point-dot'
  return document.createElement('div')
}

const lineMaterial = () =>
  new LineDashedMaterial({
    color: 0x00ff00,
    linewidth: 2,
    depthTest: false,
    dashSize: 0.2,
    gapSize: 0.2
  })
