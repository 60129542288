export default {
  axes: {
    show: true,
    size: 5,
    xAxisColor: 0x63c735,
    yAxisColor: 0xf55193,
    zAxisColor: 0xd7c226
  },
  floor: {
    show: true,
    width: 1000,
    depth: 1000,
    color: 0x888888,
    opacity: 0.1,
    yPosition: 0
  },
  global: {
    backgroundColor: 0xeeeeee,
    ambientLightIntensity: 0.5,
    directionalLightIntensity: 0.5
  },
  grid: {
    show: false
  }
}
