/** It returns attribute data-type according to the contained referred
 * cypher at the ifcClass
 * @param type Number about data structure nature of the attribute */
export default type => {
  switch (type) {
    case '0': return 'Numeric'
    case '1': return 'Text | List'
    case '2': return 'Object Labeled Value'
    case '3': return 'Text'
    case '4': return 'Numeric'
    case '5': return 'Numeric'
    default: return type
  }
}
