import { AxesHelper, BoxGeometry, Color, Mesh, MeshBasicMaterial } from 'three'

import * as THREE from 'three'

import settings from '../../config/scene'
import { SimpleGrid } from 'openbim-components'
import addDefaultLights from '@/controllers/mountViewer/add-default-lights'

/** Configs for the scene at the viewer
 * @param viewer instance where the geometries will be rendered
 * @returns scene instance*/
export default async viewer => {
  const scene = await viewer.scene.get()

  addDefaultLights(scene)

  scene.background = new Color(settings.global.backgroundColor)

  /** Adding parametrized scene-items from settings file */
  if (settings.axes.show) {
    const {
      axes: { size, xAxisColor, yAxisColor, zAxisColor }
    } = settings

    const axes = new AxesHelper(size)

    axes.setColors(xAxisColor, yAxisColor, zAxisColor)
    scene.add(axes)
  }

  if (settings.floor.show) {
    const {
      floor: { width, depth, color, opacity, yPosition }
    } = settings

    const floor = new Mesh(
      new BoxGeometry(width, -0.001, depth),
      new MeshBasicMaterial({
        color,
        opacity,
        transparent: true
      })
    )

    floor.castShadow = true
    floor.receiveShadow = true

    floor.position.set(0, yPosition, 0)

    scene.add(floor)
    viewer.meshes.push(floor)
  }

  if (settings.grid.show) {
    new SimpleGrid(viewer, new THREE.Color('#5e7695'), 5, 5, 100)
  }

  return scene
}
