import { DoubleSide } from 'three'
import store from '@/store'

export default items => {
  const { viewer, culler } = store.getters

  for (const item of items) {
    viewer.meshes.push(item.mesh)
    culler.add(item.mesh)

    for (const mat of item.mesh.material) {
      mat.side = DoubleSide
    }
  }

  culler.needsUpdate = true
}
