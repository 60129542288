export default (properties, expressId) => {
  const ifcRelDefinesByProperties = 4186316022

  const allPsetsRels = Object.values(properties).filter(
    item => item.type === ifcRelDefinesByProperties
  )

  const relatedPsetsRels = allPsetsRels.filter(item =>
    item.RelatedObjects.map(r => r.value).includes(expressId)
  )

  return relatedPsetsRels.map(
    item => properties[item.RelatingPropertyDefinition.value]
  )
}
