/** It opens docs-resources on a new tab
 * @param isDocsServer about if the resource wanted is for frontend or backend */
export default (isDocsServer = false) => {
  window
    .open(
      isDocsServer
        ? 'https://testifc.mtbsoftware.net/docs-server/index.html'
        : 'https://testifc.mtbsoftware.net/docs/modules/controllers.html',
      '_blank'
    )
    .focus()
}
