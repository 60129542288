<template>
  <div id="app">
    <Home />
    <!--    <Test />-->
    <!--    <Test2 />-->
  </div>
</template>

<script>
import Home from './views/Home.vue'
// import Test from './views/Test.vue'
// import Test2 from './views/Test2.vue'

export default {
  name: 'App',
  components: {
    Home
    // Test
    // Test2
  }
}
</script>
