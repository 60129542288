<template>
  <div v-if="attributes" class="panel">
    <span
      @click="close"
      style="position: absolute; top: 2px; right: 2px; cursor: pointer"
      v-text="'x'"
    />
    <div v-for="(a, index) in attributes" :key="index">
      <p style="width: 30%; font-weight: 600" v-text="`${index}`" />
      <p style="width: 30%" v-text="getType(a.type)" />
      <p v-if="a.isCommon" style="width: 30%" v-text="'Common'" />
      <p v-else style="width: 30%; color: #a63d3d" v-text="'Not common'" />
    </div>
  </div>
</template>

<script lang="ts">
import { getType } from '../../helpers'
import store from '../../store'

export default {
  computed: {
    attributes() {
      return store.getters.attributes
    }
  },
  methods: {
    getType,

    close() {
      store.dispatch('setAttributes', null)
    }
  }
}
</script>
