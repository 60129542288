/** Geometry highlight function */
import store from '@/store'

export default (result, removePrevious, toSubViewer = true) => {
  const { fragmentsHighlighter } = store.getters

  if (result) {
    let lastSelection = {}

    for (const fragment of result.fragments) {
      lastSelection[fragment.id] = [result.id]
    }

    const item = { [result.fragments[0].id]: [result.id] }

    fragmentsHighlighter.highlightByID('highlight', item, removePrevious)

    fragmentsHighlighter.update()

    if (store.getters.viewer2D && toSubViewer) {
      const selection = fragmentsHighlighter.selection['highlight']

      const event = new CustomEvent('highlight-express-ids', {
        detail: {
          selection,
          removePrevious
        }
      })

      document.dispatchEvent(event)
    }
  } else {
    fragmentsHighlighter.clear('highlight')
    fragmentsHighlighter.clear('preselection')

    document.body.style.cursor = 'default'

    if (store.getters.viewer2D && toSubViewer) {
      const selection = fragmentsHighlighter.selection['highlight']

      const event = new CustomEvent('highlight-express-ids', {
        detail: {
          selection,
          removePrevious
        }
      })

      document.dispatchEvent(event)
    }
  }

  document.dispatchEvent(
    new CustomEvent('highlight-item', {
      detail: { result }
    })
  )

  return result
}
