import store from '@/store'
import handleTraceLines from '@/controllers/modes/mouse-click/handle-trace-lines'

export default async () => {
  const { viewer, points, traceLines } = store.getters

  const cast = viewer.raycaster.castRay()

  if (cast) {
    const allPoints = [...points, cast.point]

    await store.dispatch('setPoints', allPoints)

    if (allPoints.length > 1 && traceLines) {
      /** Creates coordinates for GetPoint new trace line */
      // handleTraceLines(allPoints)
    } else if (allPoints.length > 1 && !traceLines) {
      /** Free-points length measurement (no-trace) */

      const distances = []

      for (let i = 1; i < points.length; i++) {
        distances.push(points[i - 1].distanceTo(points[i]))
      }

      await store.dispatch('setDistances', distances)
    }
  }
}
