import store from '@/store'

export default async () => {
  const { viewer, fragmentsHighlighter } = store.getters

  viewer.raycaster.mouse.offset = { x: 0, y: 0 }

  const cast = viewer.raycaster.castRay()

  if (cast) {
    await viewer.camera.controls.setTarget(
      cast.point.x,
      cast.point.y,
      cast.point.z
    )

    viewer.camera.controls.distance = 7

    await store.dispatch('setZoomObjectMode', false)

    fragmentsHighlighter.highlightByID('preselection', null, true)
    fragmentsHighlighter.highlightByID('highlight', null, true)
  }
}
