import state from './state'
import { capitalize } from 'eslint-plugin-vue/lib/utils/casing'

let mutations = {}

for (const s in state) {
  mutations = {
    ...mutations,
    [`set${capitalize(s)}`]: (st, value) => {
      state[s] = value
    }
  }
}

export default mutations
