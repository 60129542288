import initViewer from './init-viewer'
import setScene from './set-scene'
import { FragmentManager, ScreenCuller } from 'openbim-components'
import setHighlight from '@/controllers/mountViewer/set-highlight'
import store from '@/store'
import mountZoomWindow from '@/controllers/mountZoomWindow'
import { FragmentProperties } from 'openbim-components/fragments/fragment-properties'

/**
 * set instances of viewer, which is mounted at DOM and
 * fragments, necessary to render and parse the .ifc model
 * */
export default async () => {
  /** DOM element */
  const wrapper = document.getElementById('viewer')

  /** viewer & renderer instances assignation */
  const { viewer } = initViewer(wrapper)

  const fragments = new FragmentManager(viewer)

  const scene = await setScene(viewer)

  setHighlight(viewer, fragments)

  const culler = new ScreenCuller(viewer)

  wrapper.onmouseup = () => (culler.needsUpdate = true)
  wrapper.onwheel = () => (culler.needsUpdate = true)
  wrapper.onresize = () => (culler.needsUpdate = true)

  await store.dispatch('setViewer', viewer)
  await store.dispatch('setFragments', fragments)
  await store.dispatch('setScene', scene)
  await store.dispatch('setCuller', culler)
  await store.dispatch('setPropertiesMapper', new FragmentProperties())

  mountZoomWindow()

  setTimeout(() => {
    window.dispatchEvent(new Event('resize'))
  }, 100)
}
