import store from '@/store'

/**
 * @param endpointPath path for API-server
 * @param method type of API query
 * @param body payload data for query
 * @param isReadable establishes if response is json or stream
 * @return Promise<any> */
export default async (
  endpointPath,
  method = 'GET',
  body,
  isReadable = false
) => {
  let url = `${store.getters.serverUrl}${endpointPath}`

  let data

  // query-params write
  if (method === 'GET') {
    if (body && Object.keys(body).length) {
      url += '?'

      for (const k in body) {
        url += `${k}=${body[k]}`

        if (Object.keys(body).indexOf(k) !== Object.keys(body).length - 1) {
          url += '&'
        }
      }
    }

    data = await fetch(url)
  } else {
    const headers = {
      'Content-Type': 'application/json'
    }

    data = body
      ? await fetch(url, { method, body: JSON.stringify(body), headers })
      : await fetch(url, { method, headers })
  }

  // if isReadable, then returns stream-file read, opposite returns the json response
  if (data) {
    return isReadable
      ? new Uint8Array(await data.arrayBuffer())
      : await data.json()
  }
}
