<template>
  <div style="display: flex; position: relative">
    <div
      @click="mouseClickEvents"
      @dblclick="doubleClickEvents"
      id="viewer"
      :style="
        viewerMode === null || viewerMode === 2
          ? 'height: 100vh; width: 100vw'
          : 'height: 100vh; width: 50vw'
      "
    >
      <canvas
        id="markers"
        style="position: absolute"
        :style="`z-index: ${zoomWindowMode ? 2000 : -1}`"
      />
      <PanelAttributes />
      <LoadingBar />
      <PropertiesPanel v-if="objectProperties" />
      <input
        id="input"
        type="file"
        accept=".ifc"
        hidden
        @change="checkAlreadyCreatedBucket($event.target.files, bucketId)"
      />
    </div>
    <div
      v-if="viewerMode === 1 || viewerMode === 2"
      id="viewer-2d-split"
      :class="viewerMode === 1 ? 'viewer2d-split' : 'viewer2d-float'"
      :style="`top: ${topFloating}px; left: ${rightFloating}px`"
    >
      <canvas
        v-if="selectionWindow"
        id="selection-window"
        @click.stop=""
        style="
          position: absolute;
          z-index: 5000;
          height: 100%;
          width: 100%;
          background: transparent;
        "
      />
      <div class="split-screen-btns">
        <img
          @click.stop="selectionWindow = !selectionWindow"
          src="https://www.svgrepo.com/show/446308/rectangle-4.svg"
          class="viewer-icon"
          style="height: 20px; z-index: 6000"
          :style="selectionWindow ? 'background: green' : ''"
          alt="selection-window"
        />
        <img
          v-if="viewerMode === 1"
          @click.stop="addSplitScreen(2)"
          src="https://www.svgrepo.com/show/488632/minimize.svg"
          class="viewer-icon"
          alt="minimize"
        />
        <img
          v-if="viewerMode === 2"
          @click.stop="addSplitScreen(1)"
          src="https://www.svgrepo.com/show/513834/maximize.svg"
          class="viewer-icon"
          alt="minimize"
        />
        <img
          @click.stop="closeViewer2D"
          src="https://www.svgrepo.com/show/510924/close-md.svg"
          class="viewer-icon"
          style="height: 20px"
          alt="close"
        />
      </div>
      <div
        class="split-screen-level"
        :style="viewerMode === 1 ? '' : 'flex-direction: column'"
      >
        <div
          class="flex items-center"
          :style="viewerMode === 1 ? 'margin: 12px' : 'margin: 4px'"
        >
          <label
            for="c"
            style="margin-right: 8px"
            v-text="'Perspective camera'"
          />
          <input
            @click.stop="toggleProjection"
            id="c"
            type="checkbox"
            :checked="isPerspective"
          />
        </div>
        <div
          class="flex items-center"
          :style="viewerMode === 1 ? 'margin: 12px' : 'margin: 4px'"
        >
          <label
            for="level-amount"
            style="margin-right: 8px"
            v-text="'Change ifc-levels at both viewers'"
          />
          <input
            type="checkbox"
            id="level-amount"
            :checked="isBothViewers"
            @click.stop="changeBothViewers"
          />
        </div>
      </div>
      <div
        style="
          position: absolute;
          right: -10px;
          top: 60px;
          display: flex;
          flex-direction: column;
        "
      >
        <label for="b" v-text="`${inputIfcLevel.height.value}m`" />
        <input
          @click.stop=""
          id="b"
          @change="adjustLevels"
          step="0.1"
          type="range"
          :min="inputIfcLevel.base.value"
          :max="ifcLevel.height"
          style="width: 100%; transform: rotate(-90deg); margin-top: 60px"
          v-model="inputIfcLevel.height.value"
        />
      </div>
      <img
        v-if="viewerMode === 2"
        id="draggable"
        src="https://www.svgrepo.com/show/487295/drag-indicator.svg"
        class="viewer-icon-grab"
        draggable="true"
        alt="move"
        @dragstart="dragStart"
        @drag="checkDrag"
        @dragend="endDrag"
      />
    </div>
  </div>
</template>

<script type="module">
//#region Imports
import store from '../../store/index'
import computed from '../../store/getters'
import allTypes from '@/config/all-types'
import constants from '@/config/constants'
import LoadingBar from './LoadingBar'
import PropertiesPanel from '@/components/Viewer/PropertiesPanel.vue'
import PanelAttributes from '@/components/Viewer/PanelAttributes.vue'
import renderAssets from '@/controllers/renderAssets'
import mount2DViewer from '@/controllers/mount2DViewer'
import defineMeshes from '@/controllers/renderGlb/define-meshes'
import listRenderedAssets from '@/controllers/renderGlb/list-rendered-assets'
import watchSelectionWindow from '@/controllers/watch-selection-window'
import mouseClick from '@/controllers/modes/mouse-click'
import highlightResult from '@/controllers/highlight/highlight-result'
import { fetcher, fetcherFiles, paramsStr, showBrowser } from '@/helpers'
import { OrthoPerspectiveCamera } from 'openbim-components'
import CameraControls from 'camera-controls'

import {
  BufferGeometry,
  DoubleSide,
  EdgesGeometry,
  Line,
  LineBasicMaterial,
  LineDashedMaterial,
  LineSegments,
  MeshLambertMaterial,
  Vector3
} from 'three'

import {
  mountViewer,
  parseIfcFile,
  renderGlb,
  toggleVisibleItems
} from '@/controllers'
import addTraceLine from '@/helpers/add-trace-line'
//#endregion

let _this

export default {
  //#region Data-core
  name: 'Viewer',

  components: { PropertiesPanel, LoadingBar, PanelAttributes },

  /** Direct import and assignment vuex-getters to computed */
  computed,

  props: ['server', 'ifc-file'],

  data() {
    return constants
  },
  //#endregion

  //#region Lifecycle-hooks
  created() {
    _this = this

    store.dispatch(
      'setServerUrl',
      _this.server ? _this.server : process.env.VUE_APP_SERVER_URL
    )
  },

  /** Mounted lifecycle-hook.
   * After viewer is mounted, it sets zoom-window frame & events capture */
  async mounted() {
    /** Viewer & Fragments arch are here */
    await mountViewer()

    /** Events listen functions */
    _this.mouseOverEvents()
    _this.hearKeyboardEvents()
    _this.rootEvents()
  },
  //#endregion

  //#region Functionalities
  methods: {
    //#region Viewer events setup
    /** Viewer mouseover event capture */
    mouseOverEvents() {
      const {
        fragmentsHighlighter,
        fpMode,
        picker,
        pickingMode,
        pointMode,
        points,
        scene,
        tools,
        traceLines,
        viewer,
        volumeMode,
        volumeTraceMode,
        zoomObjectMode
      } = store.getters

      /** Needed to show preview-point */
      const dimensions = tools.dimensionsTool // store.getters.tools.tools[0]

      let edges = null

      viewer.renderer.get().domElement.addEventListener('mousemove', () => {
        const cast = viewer.raycaster.castRay()

        if (cast) {
          if (pointMode) {
            dimensions.enabled = true
            dimensions.visible = true

            store.dispatch('setCurrentPoint', cast.point)

            if (traceLines && points.length) {
              _this.drawTemporaryLine(cast.point)
            }
          }

          /** At these modes, cursor is changed and highlights preselection are calculated */
          if (zoomObjectMode || pickingMode || picker) {
            document.body.style.cursor = 'crosshair'
            _this.preselectGeom()
          }

          if (volumeMode || volumeTraceMode) {
            dimensions.enabled = true
            dimensions.visible = true

            if (!edges) {
              edges = new LineSegments(
                new EdgesGeometry(cast.object.geometry, 90),
                new LineBasicMaterial({ color: '#fff' })
              )

              scene.add(edges)
            }
          }
        } else {
          if (!fpMode) {
            viewer.camera.controls.enabled = true
          }

          /** Clean edges geometries */
          if (edges) {
            scene.remove(edges)
            edges.visible = false
            edges = null
          }

          if (pointMode) {
            document.body.style.cursor = 'default'
          }

          /** Previous-highlight to selection */
          if (pickingMode || picker) {
            fragmentsHighlighter.clear('preselection')

            document.body.style.cursor = 'default'
          }
        }
      })
    },

    /** Binds keyboard actions to events as close perimeters / areas */
    hearKeyboardEvents() {
      document.addEventListener('keydown', ({ key }) => {
        if (key === 'Enter') _this.keyEnterEvents()
        else if (key === 'Escape') _this.keyEscapeEvents()
      })
    },

    rootEvents() {
      _this.$root.$on('express-ids-subviewer', payload => {
        const { fragmentsHighlighter } = store.getters

        if (payload) {
          const { result, removePrevious } = payload

          highlightResult(result, removePrevious, false)
        } else {
          fragmentsHighlighter.clear('highlight')
        }
      })

      document.addEventListener('highlight-item', ({ detail: { result } }) => {
        _this.$emit('highlight', result)
      })
    },
    //#endregion

    //#region Ifc-process bucket & render
    /** Process retrieved ifc model from url as fragments file set */
    async ifcProcess(bucket, url, modelName) {
      const file = await _this.processIfcFile(url)
      return _this.generateBucketFromUrl(bucket, modelName, file, url)
    },

    async processIfcFile(url) {
      await store.dispatch('setLoading', true)

      await _this.resetViewer()

      const data = await fetch(url)

      const ifcData = await data.text()

      const file = new File(
        [new Blob([ifcData], { type: 'text/plain' })],
        'ifc-processed.ifc'
      )

      await store.dispatch('setLoading', false)

      return file
    },

    parseIfcFile() {
      _this.$emit('get-bucket-files', { files: _this.processedBucketFiles })
    },

    async storeBucketForm(bucket, modelTitle) {
      const buckets = await _this.listBuckets()

      if (!buckets.includes(bucket)) {
        await _this.saveBucket(bucket, modelTitle)
      } else {
        await _this.saveModel(bucket, modelTitle)
      }
    },

    async saveBucket(bucket, modelTitle) {
      try {
        await fetcher('buckets/create', 'POST', {
          bucket,
          modelTitle
        })

        await store.dispatch('setBucketId', bucket)

        await store.dispatch('setBuckets', [..._this.buckets, bucket])

        await _this.sendFilesToBucket(
          _this.processedBucketFiles,
          bucket,
          modelTitle
        )

        return true
      } catch (error) {
        console.error('error', error)
      }
    },

    async saveModel(bucket, modelTitle) {
      try {
        const createdSubfolder = await fetcher(
          'buckets/add-model-folder',
          'POST',
          {
            bucket,
            modelTitle
          }
        )

        if (createdSubfolder) {
          await _this.sendFilesToBucket(
            _this.processedBucketFiles,
            bucket,
            modelTitle
          )

          return true
        }
      } catch (error) {
        console.error(error)
      }
    },
    //#endregion

    //#region Lists operations
    /** Gives the created buckets from server */
    async listBuckets() {
      await store.dispatch('setLoading', true)

      const buckets = await fetcher('buckets')

      await store.dispatch('setBuckets', buckets)

      await store.dispatch('setLoading', false)

      return buckets
    },

    /** Retrieve ifc-classes from bucket */
    async getIfcClasses(bucket) {
      await store.dispatch('setLoading', true)

      const ifcClasses = await fetcher('ifc-classes', 'GET', {
        bucket
      })

      await store.dispatch('setIfcClasses', ifcClasses)

      await store.dispatch('setLoading', false)

      return ifcClasses
    },

    async getAttributes(bucket, ifcClass) {
      await store.dispatch('setLoading', true)

      const attributes = await fetcher('ifc-classes/attributes', 'GET', {
        bucket,
        ifcClass
      })

      await store.dispatch('setAttributes', attributes)

      await store.dispatch('setLoading', false)

      return attributes
    },

    /** Retrieve ifc-classes that were set as contexts ones */
    async getContextList(bucket) {
      await store.dispatch('setLoading', true)

      const contextIfcClasses = await fetcher('ifc-classes/contexts', 'GET', {
        bucket
      })

      await store.dispatch('setContextIfcClasses', contextIfcClasses)

      await store.dispatch('setLoading', false)

      return contextIfcClasses
    },

    /** Sign selected ifc-classes as contexts in bucket */
    async setContext(bucket, ifcClasses, isContext) {
      await store.dispatch('setLoading', true)

      const contextIfcClasses = await fetcher('ifc-classes/contexts', 'POST', {
        bucket,
        ifcClasses,
        isContext
      })

      if (isContext) {
        await store.dispatch('setContextIfcClasses', [
          ...store.getters.contextIfcClasses,
          ...contextIfcClasses
        ])
      } else {
        await store.dispatch(
          'setContextIfcClasses',
          store.getters.contextIfcClasses.filter(c => {
            if (!ifcClasses.includes(c)) {
              return c
            }
          })
        )
      }

      await store.dispatch('setLoading', false)

      return store.getters.contextIfcClasses
    },

    /** Retrieves attributes information according to selected ifc class */
    async getIfcAssets(bucket, ifcClass) {
      await store.dispatch('setLoading', true)

      const ifcAssets = await fetcher('ifc-classes/assets', 'GET', {
        bucket,
        ifcClass
      })

      await store.dispatch('setIfcClassExpressIds', ifcAssets)

      await store.dispatch('setLoading', false)

      return ifcAssets
    },

    /** Mark the selected assets as removed (it not deletes literally at the bucket) */
    async markAsDeletedAssets(bucket, ifcAssets) {
      await store.dispatch('setLoading', true)

      const deletedBucketAssets = await fetcher('assets/delete', 'POST', {
        bucket,
        ifcAssets
      })

      await store.dispatch('setDeletedBucketObjects', deletedBucketAssets)

      await store.dispatch('setLoading', false)

      return deletedBucketAssets
    },

    /** Get the assets marked as deleted */
    async listDeletedAssets(bucket) {
      await store.dispatch('setLoading', true)

      const deletedAssets = await fetcher('assets/deleted', 'GET', {
        bucket
      })

      await store.dispatch('setDeletedBucketObjects', deletedAssets)

      await store.dispatch('setLoading', false)

      return deletedAssets
    },

    /** Recover default non deleted status of selected assets */
    async restoreDeletedAssets(bucket, ifcAssets) {
      await store.dispatch('setLoading', true)

      const deletedAssets = await fetcher('assets/restore', 'POST', {
        bucket,
        ifcAssets
      })

      await store.dispatch('setDeletedBucketObjects', deletedAssets)

      await store.dispatch('setLoading', false)

      return deletedAssets
    },

    /** Bucket files recovering */
    async restoreBucket(bucket) {
      await store.dispatch('setLoading', true)

      const originsModels = await fetcher('buckets/regenerate', 'POST', {
        bucket
      })

      for (const originModel of originsModels) {
        const { origin, model } = originModel

        const file = await _this.processIfcFile(origin)

        await _this.parseFilesFromIfc(file, bucket, model)
      }

      await store.dispatch('setLoading', false)

      return true
    },

    /** Get the uploaded file & checks if previously a bucket-folder was created.
     *  If opposite, sends data to create only a sub-folder for the ifc */
    checkAlreadyCreatedBucket(files, bucket) {
      const file = files[0]

      const modelTitle = file.name.replace('.ifc', '')

      return bucket
        ? _this.createSubfolder(bucket, modelTitle, file)
        : _this.createBucket(file, modelTitle)
    },

    /** Starts a bucket structure at server and send ifc file to be parsed */
    async createBucket(file, modelTitle) {
      try {
        const createdBucketUuid = await fetcher('buckets/create', 'POST', {
          bucket: '',
          modelTitle
        })

        if (createdBucketUuid && createdBucketUuid.error === undefined) {
          await store.dispatch('setBucketId', createdBucketUuid.created)

          await store.dispatch('setBuckets', [
            ..._this.buckets,
            createdBucketUuid.created
          ])

          await _this.parseFilesFromIfc(
            file,
            createdBucketUuid.created,
            modelTitle
          )

          return createdBucketUuid.created
        } else {
          console.error(
            'Error during create. Maybe the bucket-id already exists'
          )
        }
      } catch (error) {
        console.error('error', error)
      }
    },

    async generateBucketFromUrl(bucket, modelTitle, file, url) {
      const createdBucketUuid = await fetcher('buckets/create', 'POST', {
        bucket,
        modelTitle
      })

      if (createdBucketUuid && createdBucketUuid.error === undefined) {
        await fetcher('buckets/add-origin', 'POST', {
          bucket,
          url,
          model: modelTitle
        })

        await store.dispatch('setBucketId', createdBucketUuid.created)

        await _this.parseFilesFromIfc(
          file,
          createdBucketUuid.created,
          modelTitle
        )

        await _this.renderBucketModels(bucket, true, [], modelTitle)

        return createdBucketUuid.created
      } else {
        console.error('Error during create. Maybe the bucket-id already exists')
      }
    },

    /** Adds a new folder to a previously created bucket when multiple models are rendered */
    async createSubfolder(bucket, modelTitle, file) {
      try {
        const createdSubfolder = await fetcher(
          'buckets/add-model-folder',
          'POST',
          {
            bucket,
            modelTitle
          }
        )

        if (createdSubfolder) {
          await _this.parseFilesFromIfc(file, bucket, modelTitle)

          return createdSubfolder
        }
      } catch (error) {
        console.error('error', error)
      }
    },
    //#endregion

    //#region Render operations
    /** Ifc file to fragments transformation */
    async parseFilesFromIfc(file, bucket, modelTitle, sendToBucket = true) {
      const reader = new FileReader()

      reader.onload = async () => {
        const arrayBuffer = reader.result
        const uint8Array = new Uint8Array(arrayBuffer)

        store.getters.fragments.list = {}

        const bucketFiles = await parseIfcFile(uint8Array)

        if (sendToBucket) {
          await _this.sendFilesToBucket(bucketFiles, bucket, modelTitle)
        } else {
          _this.processedBucketFiles = bucketFiles
        }
      }

      reader.readAsArrayBuffer(file)
    },

    /** Send parsed files to server storage */
    async sendFilesToBucket(bucketFiles, bucket, modelTitle) {
      try {
        const { files, geometriesBinaries } = bucketFiles

        for (const file of files) {
          await fetcherFiles('buckets/start-file', {
            bucket,
            modelTitle,
            file
          })
        }

        await fetcherFiles(
          'buckets/start-file',
          {
            bucket,
            modelTitle,
            geometriesBinaries
          },
          true
        )

        const properties = [{ [modelTitle]: {} }]

        const reader = new FileReader()

        reader.onload = async ({ target: { result } }) => {
          properties[properties.length - 1][modelTitle] = JSON.parse(result)
        }

        reader.readAsText(files[0])

        await store.dispatch('setModels', [...store.getters.models, modelTitle])
        await store.dispatch('setProperties', properties)

        return true
      } catch (error) {
        console.error('⭐error⭐', error)
      }
    },

    /** Shows or hide selected assets */
    toggleVisibleItems(ifcAssets) {
      toggleVisibleItems(ifcAssets)
    },

    /** Represents any or some of the models at the viewer */
    async renderBucketModels(
      bucket,
      isContext = true,
      models = [],
      recentModelToDiscard = ''
    ) {
      await store.dispatch('setLoading', true)

      const defModels = (
        models.length ? models : await _this.getBucketModels(bucket)
      ).filter(m => m !== recentModelToDiscard)

      await store.dispatch('setLoadingTotal', defModels.length)
      await store.dispatch('setLoadingText', 'Reading bucket')

      const { files, properties } = await _this.getBucketRenderables(
        bucket,
        defModels
      )

      const hiddeableExpressIds = []

      if (!isContext) {
        const contextIfcClasses = await fetcher('ifc-classes/contexts', 'GET', {
          bucket
        })

        for (const model in properties) {
          for (const props in properties[model]) {
            for (const p in properties[model][props]) {
              if (
                contextIfcClasses.includes(
                  allTypes[properties[model][props][p].type]
                )
              ) {
                hiddeableExpressIds.push(
                  properties[model][props][p].expressID.toString()
                )
              }
            }
          }
        }
      }

      const assetsToDelete = await fetcher('assets/deleted', 'GET', {
        bucket
      })

      if (assetsToDelete.length) {
        hiddeableExpressIds.push(...assetsToDelete.map(a => a.expressId))
      }

      await store.dispatch('setProperties', properties)

      return await _this.renderModels(files, properties, hiddeableExpressIds)
    },

    /** Obtain .frag binaries for geometries rendering */
    async getBucketRenderables(bucket, models) {
      const files = []
      const properties = []

      await store.dispatch('setLoadingCurrent', 0)

      for (const model of models) {
        files.push({
          [model]: await fetcher(
            'fragments/get-binaries',
            'GET',
            {
              bucket,
              model
            },
            true
          )
        })

        properties.push({
          [model]: await fetcher('fragments/get-properties', 'GET', {
            bucket,
            model
          })
        })
      }

      return { properties, files }
    },

    /** Obtain models folder-names at a bucket */
    async getBucketModels(bucket) {
      const models = await fetcher('buckets/models', 'GET', { bucket })

      await store.dispatch('setBucketModels', models)

      return models
    },

    /** It represents at the viewer the selected assets */
    async renderAssets(bucket, ifcAssets) {
      await store.dispatch('setLoading', true)

      const models = await _this.getBucketModels(bucket)

      const { files, properties } = await _this.getBucketRenderables(
        bucket,
        models
      )

      for (const file of files) {
        const index = files.indexOf(file)
        await renderAssets(file, properties[index], models[index], ifcAssets)
      }

      await store.dispatch('setLoading', false)
    },

    /** Represents the parsed urls from bucket obtained fragments files */
    async renderModels(files, properties, contextExpressIds) {
      let counter = 0

      await store.dispatch('setLoadingTotal', files.length)
      await store.dispatch('setLoadingCurrent', counter)
      await store.dispatch('setLoadingText', 'Rendering geometries')

      const models = []

      for (const file of files) {
        const props = Object.values(
          properties.find(p => Object.keys(p)[0] === Object.keys(file)[0])
        )[0]

        await store.dispatch('setPropertiesFiles', [
          ...store.getters.propertiesFiles,
          props
        ])

        const model = await renderGlb(file)

        models.push(model.keyFragments)

        await store.dispatch('setLoadingCurrent', ++counter)
      }

      await store.dispatch('setLoading', false)

      await store.dispatch('setModels', [...store.getters.models, ...models])

      if (contextExpressIds.length) {
        _this.hideExpressIds(contextExpressIds)
      }

      setTimeout(() => {
        defineMeshes(Object.values(_this.fragments.list))
        listRenderedAssets()
      }, 300)

      return models
    },

    /** Geometries hide function */
    hideExpressIds(contextExpressIds) {
      for (const l in _this.fragments.list) {
        _this.fragments.list[l].setVisibility(false, contextExpressIds)
      }
    },

    /** Represents bucket contexts exclusively */
    async renderBucketContexts(bucket, contextIfcClasses) {
      await store.dispatch('setLoading', true)

      const _contextIfcClasses = contextIfcClasses.length
        ? contextIfcClasses
        : await fetcher('ifc-classes/contexts', 'GET', {
            bucket
          })

      if (_contextIfcClasses.length) {
        const models = await _this.getBucketModels(bucket)

        const { properties, files } = await _this.getBucketRenderables(
          bucket,
          models
        )

        const ifcAssets = []

        const cypherTypes = []

        for (const context of _contextIfcClasses) {
          const cypherClassIndex = Array.from(
            Object.values(allTypes)
          ).findIndex(a => a === context)

          cypherTypes.push(
            parseInt(Array.from(Object.keys(allTypes))[cypherClassIndex])
          )
        }

        for (const prop of properties) {
          for (const pr in prop) {
            for (const p in prop[pr]) {
              if (cypherTypes.includes(prop[pr][p].type)) {
                ifcAssets.push({
                  expressId: prop[pr][p].expressID.toString(),
                  model: models[properties.indexOf(prop)]
                })
              }
            }
          }
        }

        for (const file of files) {
          const index = files.indexOf(file)

          await renderAssets(file, properties[index], models[index], ifcAssets)
        }
      }

      await store.dispatch('setLoading', false)
    },

    /** Clean rendered geometries and free memory */
    async resetViewer() {
      store.getters.viewer.dispose()
      store.getters.tools.cubeMap.visible = false
      await mountViewer()
    },
    //#endregion

    //#region Mode switchers
    setSnapMode() {
      store.dispatch('setSnapMode', !store.getters.snapMode)
      store.dispatch('setTraceLines', false)
    },

    setPointMode(traceLines) {
      store.dispatch('setPointMode', !store.getters.pointMode)

      store.dispatch('setTraceLines', traceLines)

      store.getters.tools.dimensionsTool.setTraceLines(traceLines)
    },

    setVolumeMode() {
      store.dispatch('setVolumeMode', !store.getters.volumeMode)
    },

    setAreaMode() {
      store.dispatch('setAreaMode', !store.getters.areaMode)
    },

    setAngleMode() {
      store.dispatch('setAngleMode', !store.getters.angleMode)
    },

    setVolumeTraceMode() {
      store.dispatch('setVolumeTraceMode', !store.getters.volumeTraceMode)
    },

    setTransparentPicker(opacity) {
      store.dispatch('setTransparentPicker', !store.getters.transparentPicker)
      store.dispatch('setTransparencyRate', opacity)
    },

    setZoomWindow() {
      store.dispatch('setZoomWindowMode', !store.getters.zoomWindowMode)
    },

    setZoomToObject() {
      store.dispatch('setZoomObjectMode', !store.getters.zoomObjectMode)
    },

    toggleClippingPlanes() {
      store.dispatch('setClippingPlanesMode', !_this.clippingPlanesMode)
    },

    togglePickingMode() {
      store.dispatch('setPicker', !store.getters.picker)
    },

    togglePropertiesPicker() {
      store.dispatch(
        'setPropertiesPickingMode',
        !store.getters.propertiesPickingMode
      )
    },

    toggleGhostMode() {
      store.dispatch('setGhostMode', !store.getters.ghostMode)
    },
    //#endregion

    //#region Mouse & keyboard events
    /** Viewer clicks event capture. It can start measures functions */
    async mouseClickEvents(event) {
      await mouseClick(event)
    },

    /** Double click interactions. Creates a clipping-plane if mode is active */
    doubleClickEvents() {
      if (store.getters.clippingPlanesMode) {
        const clipper = store.getters.tools.clipperTool // store.getters.viewer.tools.tools[1]
        clipper.enabled = true
        clipper.visible = true
        clipper.size = store.getters.clippingPlanesSize

        clipper.create()
      }
    },

    /** Actions about key enter event. Calculates & closes some measure tools */
    keyEnterEvents() {
      if (store.getters.volumeTraceMode) {
        const { prismTool } = store.getters.tools

        prismTool.endCreation()

        store.dispatch('setMarkVolumeHeight', true)
      } else if (store.getters.areaMode) {
        const { areaTool } = store.getters.tools

        store.dispatch('setAreaMode', false)

        return areaTool.endCreation()
      } else if (
        store.getters.pointMode &&
        store.getters.traceLines &&
        store.getters.points.length > 2
      ) {
        const perimeter = _this.closeTraceLinesPerimeter()

        // store.getters.scene.remove(store.getters.tempLine)

        store.dispatch('setPointMode', false)
        store.dispatch('setSnapMode', false)

        const { dimensionsTool } = store.getters.tools

        dimensionsTool.enabled = false

        return perimeter
      }
    },

    /** Modes cancelled when escape key is pressed */
    keyEscapeEvents() {
      store.dispatch('setZoomObjectMode', false)
      store.dispatch('setVolumeMode', false)
      store.dispatch('setSnapMode', false)
      store.dispatch('setPointMode', false)
      store.dispatch('setPickingMode', false)
      store.dispatch('setAngleMode', false)
      store.dispatch('setAreaMode', false)

      const { tools } = _this.viewer.tools

      for (const t in tools) {
        tools[t].enabled = false
        tools[t].visible = false
      }

      store.getters.fragmentsHighlighter.clear()

      document.body.style.cursor = 'default'
    },

    // Assets selection
    /** Selects clicked asset at viewer */
    pickSelectedExpressIds(ifcAssets) {
      const selected = _this.getSelected(ifcAssets)

      const { fragmentsHighlighter } = store.getters

      fragmentsHighlighter.update()
      fragmentsHighlighter.highlightByID('highlight', selected, true)
    },

    /** Settings for assets selection */
    getSelected(ifcAssets) {
      let selected = {}

      for (const asset of ifcAssets) {
        const mesh = store.getters.viewer.meshes.find(
          m => m.uuid === asset.uuid
        )

        if (!mesh) return

        const { uuid } = mesh

        if (!selected[uuid]) {
          Object.assign(selected, { [uuid]: [asset.expressId] })
        } else {
          selected[asset.uuid].push(asset.expressId)
        }
      }

      return selected
    },
    //#endregion

    //#region GetPoint trace line mode
    /** Reset drawing directly at viewer for GetPoint line trace mode */
    drawTemporaryLine(endPoint) {
      store.getters.scene.remove(store.getters.tempLine)

      const startPoint = _this.points[_this.points.length - 1]

      const lineGeometry = new BufferGeometry().setFromPoints([
        new Vector3(startPoint.x, startPoint.y, startPoint.z),
        new Vector3(endPoint.x, endPoint.y, endPoint.z)
      ])

      const material = new LineDashedMaterial({
        color: 0x00ff00,
        dashed: true,
        dashSize: 1,
        depthTest: false,
        gapSize: 1,
        linewidth: 2
      })

      store.dispatch('setTempLine', new Line(lineGeometry, material))

      store.getters.viewer.scene.get().add(store.getters.tempLine)
    },

    /** Calculates and closes perimeter at GetPoint trace lines mode */
    closeTraceLinesPerimeter() {
      const points = store.getters.points

      const start = new Vector3(
        points[points.length - 1].x,
        points[points.length - 1].y,
        points[points.length - 1].z
      )

      const end = new Vector3(points[0].x, points[0].y, points[0].z)

      const lineClose = addTraceLine(start, end)

      store.dispatch('setDistances', [
        ...store.getters.distances,
        lineClose._length
      ])

      return store.getters.distances.reduce((a, b) => a + b)
    },

    getClickedPoints() {
      return store.getters.points
    },
    //#endregion

    //#region Clipping-planes
    /** Shows or hides the clipping-plane gizmo */
    toggleShowClippingPlanes() {
      store.dispatch('setClippingPlanesMode', !store.getters.clippingPlanesMode)

      const {
        tools: { clipperTool }
      } = store.getters

      clipperTool.material.opacity = store.getters.clippingPlanesMode ? 0.2 : 0
    },

    /** Activates or deactivates clipping-planes tool */
    toggleDeactivateClippingPlanes() {
      store.dispatch('setClippingPlanesMode', !store.getters.clippingPlanesMode)

      const { clippingPlanesMode } = store.getters

      const {
        tools: { clipperTool }
      } = store.getters

      clipperTool.visible = clippingPlanesMode
      clipperTool.enabled = clippingPlanesMode
    },

    /** Erase created clipping planes at the scene */
    deleteClippingPlanes() {
      const {
        tools: { clipperTool }
      } = store.getters

      clipperTool.deleteAll()

      store.dispatch('setClippingPlanesMode', false)
    },

    /** Add a clipping plane to the scene at height 0 */
    createClippingPlane() {
      const { tools } = store.getters

      const clipper = tools.clipperTool
      clipper.enabled = true
      clipper.visible = true
      clipper.size = store.getters.clippingPlanesSize

      clipper.createFromNormalAndCoplanarPoint(
        new Vector3(0, -1, 0),
        new Vector3(0, 0, 0),
        false
      )
    },

    async createSectionPlan(bucket, sectionPlaneName) {
      const {
        tools: { clipperTool }
      } = store.getters

      const sectionPlan = clipperTool._planes[clipperTool._planes.length - 1]

      const position = sectionPlan._origin
      const normal = sectionPlan._normal

      await fetcher('ifc-section-planes', 'POST', {
        bucket,
        sectionPlaneName,
        position,
        normal
      })
    },

    async getSectionPlanes(bucket) {
      const sections = await fetcher('ifc-section-planes', 'GET', {
        bucket
      })

      await store.dispatch('setSections', sections)

      return sections
    },

    loadSectionPlane(section) {
      const {
        tools: { clipperTool }
      } = store.getters

      const normal = new Vector3(
        section.normal.x,
        section.normal.y,
        section.normal.z
      )

      const position = new Vector3(
        section.position.x,
        section.position.y,
        section.position.z
      )

      clipperTool.createFromNormalAndCoplanarPoint(normal, position)
    },
    //#endregion

    //#region Different tool modes */
    checkModes() {
      const {
        angleMode,
        areaMode,
        pointMode,
        snapMode,
        traceLines,
        volumeTraceMode,
        tools: { dimensionsTool, areaTool, angleTool, prismTool }
      } = store.getters

      const reset = tool => {
        tool.enabled = false
      }

      reset(dimensionsTool)
      reset(areaTool)
      reset(angleTool)
      reset(prismTool)

      if (angleMode) {
        angleTool.visible = true
        angleTool.enabled = true
      } else if (snapMode || (pointMode && traceLines)) {
        dimensionsTool.visible = true
        dimensionsTool.enabled = true
      } else if (areaMode) {
        areaTool.visible = true
        areaTool.enabled = true
      } else if (volumeTraceMode) {
        prismTool.visible = true
        prismTool.enabled = true
      } else if (!pointMode || !areaMode || angleMode || volumeTraceMode) {
        dimensionsTool.enabled = false
        areaTool.enabled = false
        angleTool.enabled = false
      }
    },
    //#endregion

    //#region Zoom tools
    /** Zoom to all scene geometries */
    async setZoomExtended() {
      await store.getters.viewer.camera.fit()
    },

    /** Zoom to selected assets */
    async setZoomSelected(ifcAssets) {
      const { fragmentsHighlighter, viewer, fragments } = store.getters

      const meshes = []

      for (const asset of ifcAssets) {
        meshes.push(fragments.list[asset.uuid].mesh)
      }

      await viewer.camera.fit(meshes, 0.5)

      fragmentsHighlighter.update()

      fragmentsHighlighter.clear()

      fragmentsHighlighter.highlightByID(
        'highlight',
        _this.getSelected(ifcAssets),
        true
      )
    },

    /** Locates camera according user point introduced */
    zoomCenter(zoomPoint) {
      const { x, y, z, radius } = zoomPoint

      _this.setCameraPosition(
        {
          x,
          y,
          z
        },
        {
          x: 0,
          y: 0,
          z: 0
        },
        radius
      )
    },
    //#endregion

    //#region Geometries appearance
    /** Changes opacity of selected assets */
    setTransparentAssets(ifcAssets, transparencyRate) {
      const expressIds = ifcAssets.map(i => i.expressId)

      const { fragments } = store.getters

      for (const l in fragments.list) {
        if (fragments.list[l].items.some(e => expressIds.includes(e))) {
          const materials = fragments.list[l].mesh.material

          for (const mat of materials) {
            mat.transparent = true
            mat.opacity = transparencyRate / 10
            mat.side = DoubleSide
          }
        }
      }
    },

    /** Change ifc-class opacity */
    async setTransparentIfcClass(bucket, ifcClass, opacity) {
      const ids = await _this.getIfcAssets(bucket, ifcClass)

      const expressIds = []

      for (const key in ids) {
        expressIds.push({ expressId: ids[key].expressId })
      }

      _this.setTransparentAssets(expressIds, opacity)
    },

    /** Set ifc-class as ghost-appearance */
    async setGhostingIfcClass(bucket, ifcClass) {
      const { fragments } = store.getters

      const ids = await _this.getIfcAssets(bucket, ifcClass)

      const expressIds = []

      for (const key in ids) {
        expressIds.push(ids[key].expressId)
      }

      for (const expressId of expressIds) {
        const frags = Object.values(fragments.list).filter(l => {
          if (l.items.includes(expressId)) {
            return l
          }
        })

        if (frags.length) {
          for (const { mesh } of frags) {
            mesh.userData = mesh.material

            mesh.material = [
              new MeshLambertMaterial({
                transparent: true,
                opacity: 0.25,
                side: DoubleSide,
                color: store.getters.ghostColor
              })
            ]
          }
        }

        await store.dispatch(
          'setGhostItems',
          frags.map(({ mesh }) => mesh.uuid)
        )
      }
    },

    /** Toggle off ghosting appearance at the scene */
    async recoverGhostingIfcClass(bucket, ifcClass) {
      const ids = await _this.getIfcAssets(bucket, ifcClass)

      const expressIds = []

      for (const key in ids) {
        expressIds.push(ids[key].expressId)
      }

      for (const expressId of expressIds) {
        const frags = Object.values(fragments.list).filter(l => {
          if (l.items.includes(expressId)) {
            return l
          }
        })

        if (frags.length) {
          for (const { mesh } of frags) {
            mesh.userData = mesh.material

            mesh.material = mesh.userData
          }
        }
      }

      await store.dispatch('setGhostItems', [])
    },

    /** Settings for ghost mode */
    setGhostMode() {
      const { fragments } = store.getters

      const material = new MeshLambertMaterial({
        transparent: true,
        opacity: 0.25,
        side: DoubleSide,
        color: _this.ghostColor
      })

      for (const mesh of fragments.meshes) {
        mesh.userData = mesh.material
        mesh.material = [material]
      }
    },

    resetGhostMode() {
      const { fragments } = store.getters

      for (const mesh of fragments.meshes) {
        mesh.material = mesh.userData
        mesh.userData = {}
      }
    },
    //#endregion

    //#region Isolation
    /** Hide scene except selected assets */
    isolateSelectedExpressIds(ifcAssets) {
      const { fragments } = store.getters

      const expressIds = ifcAssets.map(i => i.expressId)

      for (const l in fragments.list) {
        fragments.list[l].setVisibility(true)

        const hidden = fragments.list[l].items.filter(
          i => !expressIds.includes(i)
        )

        fragments.list[l].setVisibility(false, hidden)
      }
    },

    /** Recover isolated assets */
    deactivateIsolate() {
      const { fragments } = store.getters

      for (const l in fragments.list) {
        fragments.list[l].setVisibility(true)
      }
    },
    //#endregion

    //#region Measures overall actions
    /** Shows or hide measures tools */
    toggleShowMeasures() {
      store.dispatch('setShowMeasures', !store.getters.showMeasures)

      const { angleTool, areaTool, dimensionsTool, prismTool } =
        store.getters.tools

      angleTool.visible = store.getters.showMeasures
      areaTool.visible = store.getters.showMeasures
      dimensionsTool.visible = store.getters.showMeasures
      prismTool.visible = store.getters.showMeasures
    },

    /** Clears all measures tools */
    deleteMeasures() {
      const { angleTool, areaTool, dimensionsTool, prismTool } =
        store.getters.tools

      angleTool.dispose()
      areaTool.dispose()
      dimensionsTool.dispose()
      prismTool.dispose()
    },
    //#endregion

    //#region IFC-Views
    /** Get the stored ifc-views at the bucket from server */
    async listIfcViews(bucket) {
      await store.dispatch('setLoading', true)

      const ifcViews = await fetcher('ifc-views', 'GET', {
        bucket
      })

      await store.dispatch('setIfcViews', ifcViews)

      await store.dispatch('setLoading', false)

      return ifcViews
    },

    /** Applies ifc-view to the scene */
    loadIfcView(ifcView) {
      if (ifcView) {
        const { cameraPosition, cameraTarget } = ifcView
        _this.setCameraPosition(cameraPosition, cameraTarget)
      }
    },

    /** Changes the camera location */
    setCameraPosition(position, target, radius = null) {
      const { viewer, culler } = store.getters

      viewer.camera.controls.setPosition(position.x, position.y, position.z)

      viewer.camera.controls.setTarget(target.x, target.y, target.z)

      if (radius !== null) {
        viewer.camera.controls.distance = radius
      }

      culler.needsUpdate = true
    },

    /** Stores current view at the bucket */
    async saveIfcView(bucket, viewName) {
      await store.dispatch('setLoading', true)

      const { viewer, ifcViews } = store.getters

      const cameraPosition = new Vector3(
        viewer.camera.activeCamera.position.x,
        viewer.camera.activeCamera.position.y,
        viewer.camera.activeCamera.position.z
      )

      const cameraTarget = new Vector3(
        viewer.camera.controls._target.x,
        viewer.camera.controls._target.y,
        viewer.camera.controls._target.z
      )

      const ifcView = {
        viewName,
        cameraPosition,
        cameraTarget
      }

      const saved = await fetcher('ifc-views', 'POST', {
        bucket,
        ifcView
      })

      if (saved) {
        await store.dispatch('setIfcViews', [...ifcViews, ifcView])
      }

      await store.dispatch('setLoading', false)

      return store.getters.ifcViews
    },
    //#endregion

    //#region Camera modes
    /** Puts camera on top and change projection to orthographic */
    setCameraPlan() {
      const { viewer } = store.getters

      const { camera } = viewer

      _this.toggleShowCubeMap(true)

      if (store.getters.fpMode) {
        viewer.camera = new OrthoPerspectiveCamera(viewer)

        camera.controls = new CameraControls(
          camera.activeCamera,
          viewer.renderer.get().domElement
        )
      } else {
        camera.controls.setPosition(0, 1, 0)
        camera.activeCamera.lookAt(0, 0, 0)
        camera.activeCamera.rotation.set(0, 0, 0)
      }

      _this.toggleShowCubeMap(true)

      setTimeout(() => {
        camera.setNavigationMode('Plan')

        if (store.getters.fpMode) {
          camera.controls.setPosition(0, 1, 0)
          camera.activeCamera.lookAt(0, 0, 0)
          camera.activeCamera.rotation.set(0, 0, 0)

          store.dispatch('setFpMode', false)
        }

        camera.fit()
      }, 200)
    },

    /** Set camera default orbit-free mode */
    setFreeCamera() {
      const { viewer } = store.getters

      const { camera } = viewer

      if (store.getters.fpMode) {
        viewer.camera = new OrthoPerspectiveCamera(viewer)

        camera.controls = new CameraControls(
          camera.get(),
          viewer.renderer.get().domElement
        )
      } else {
        camera.controls.setPosition(50, 50, 50)
        camera.controls.setTarget(0, 0, 0)
      }

      _this.toggleShowCubeMap(true)

      camera.setNavigationMode('Orbit')

      if (store.getters.fpMode) {
        camera.controls.setPosition(50, 50, 50)
        camera.controls.setTarget(0, 0, 0)

        store.dispatch('setFpMode', false)
      }

      camera.fit()
    },

    /** Set first-person POV camera */
    setFirstPersonCamera(isCollision) {
      const {
        viewer: { camera }
      } = store.getters

      _this.toggleShowCubeMap(false)

      camera.setNavigationMode('FirstPerson', isCollision)

      store.dispatch('setFpMode', true)
    },
    //#endregion

    //#region Levels features
    // async listModelsLevels(bucket) {
    //   const modelsLevels = await fetcher('levels/models', 'GET', {
    //     bucket
    //   })
    //
    //   await store.dispatch('setIfcModelsLevels', modelsLevels)
    //
    //   return modelsLevels
    // },

    loadIfcLevel(ifcLevel, splitScreenMode = 1) {
      _this.deleteClippingPlanes()

      store.dispatch('setIfcLevel', ifcLevel)

      const { baseHeight, height } = ifcLevel

      const {
        tools: { clipperTool },
        viewerMode
      } = store.getters

      clipperTool.enabled = true

      clipperTool.createFromNormalAndCoplanarPoint(
        new Vector3(0, 1, 0),
        new Vector3(0, baseHeight, 0)
      )

      clipperTool.createFromNormalAndCoplanarPoint(
        new Vector3(0, -1, 0),
        new Vector3(0, baseHeight + height, 0)
      )

      store.dispatch('setPicker', true)

      setTimeout(() => {
        clipperTool.visible = false
      }, 200)

      if (!viewerMode) {
        _this.addSplitScreen(splitScreenMode)
      } else {
        const previousState = store.getters.levelsBoth

        store.dispatch('setLevelsBoth', true)

        _this.adjustLevels()

        store.dispatch('setLevelsBoth', previousState)
      }
    },

    async listBucketLevels(bucket) {
      const bucketLevels = await fetcher('levels/bucket', 'GET', {
        bucket
      })

      await store.dispatch('setIfcBucketLevels', bucketLevels)

      return bucketLevels
    },

    async storeLevel(bucket, levelName, baseHeight, height, modelName) {
      const ifcLevel = {
        levelName,
        baseHeight: parseFloat(baseHeight),
        height: parseFloat(height),
        modelName
      }

      const ifcLevels = await fetcher('levels/create', 'POST', {
        bucket,
        ifcLevel
      })

      await store.dispatch('setIfcBucketLevels', ifcLevels)

      _this.loadIfcLevel(ifcLevel)

      return ifcLevels
    },

    // async exportLevelToBucket(bucket, ifcLevel) {
    //   const { levelName, baseHeight, height, modelName } = ifcLevel
    //
    //   await this.storeLevel(bucket, levelName, baseHeight, height, modelName)
    //
    //   await this.listBucketLevels(bucket)
    //
    //   this.loadIfcLevel(ifcLevel)
    // },

    //#endregion

    //#region Ifc-Masses
    async saveMasses(bucket, massName) {
      const ifcMasses = store.getters.masses.map((i, index) => {
        return {
          massName: `${massName}_${index + 1}`,
          points: i.points,
          height: i.height
        }
      })

      await fetcher('masses', 'POST', {
        bucket,
        ifcMasses
      })

      return await _this.listBucketMasses(bucket)
    },

    async listBucketMasses(bucket) {
      const ifcMasses = await fetcher('masses', 'GET', {
        bucket
      })

      await store.dispatch('setMasses', ifcMasses)

      return ifcMasses
    },

    loadIfcMass(ifcMass) {
      const { prismTool } = store.getters.tools

      prismTool.render(ifcMass)
    },
    //#endregion

    //#region Level sub-viewer
    addSplitScreen(mode) {
      store.dispatch('setViewerMode', mode)
    },

    closeViewer2D() {
      store.dispatch('setViewerMode', null)
      store.getters.viewer2D.dispose()

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },

    dragStart(event) {
      event.dataTransfer.setDragImage(new Image(), 0, 0)
    },

    checkDrag(event) {
      const draggable = document.getElementById('draggable')
      draggable.style.display = 'none'

      if (_this.previousXPos === 0 && _this.previousYPos === 0) {
        _this.previousXPos = event.screenX
        _this.previousYPos = event.screenY - 250 // half of container height
      } else {
        _this.rightFloating = event.screenX
        _this.topFloating = _this.previousYPos + event.screenY
      }
    },

    endDrag(event) {
      _this.rightFloating = event.screenX
      _this.topFloating = event.screenY - 100

      draggable.style.display = 'block'
    },

    adjustLevels() {
      const {
        tools: { clipperTool, clipperTool2 },
        levelsBoth
      } = store.getters

      const baseHeight = parseFloat(_this.inputIfcLevel.base.value)
      const height = parseFloat(_this.inputIfcLevel.height.value)

      if (levelsBoth) {
        clipperTool._planes.forEach(p => p.dispose())
      }

      clipperTool2._planes.forEach(p => p.dispose())

      clipperTool._planes = []
      clipperTool2._planes = []

      const create = (tool, height, isBase = true) => {
        tool.createFromNormalAndCoplanarPoint(
          new Vector3(0, isBase ? 1 : -1, 0),
          new Vector3(0, height, 0)
        )

        tool._planes[isBase ? 0 : 1]._origin.y = height

        tool._planes.forEach(p => p.update())

        tool.visible = false
      }

      if (levelsBoth) {
        create(clipperTool, baseHeight)
        create(clipperTool, height, false)
      }

      create(clipperTool2, baseHeight)
      create(clipperTool2, height, false)
    },

    toggleProjection() {
      const { viewer2D } = store.getters

      viewer2D.camera.toggleProjection()

      _this.isPerspective = !_this.isPerspective
    },

    changeBothViewers() {
      _this.isBothViewers = !_this.isBothViewers

      store.dispatch('setLevelsBoth', _this.isBothViewers)
    },
    //#endregion

    //#region Misc
    /** Geometries highlight pre-selection based on user mouse position */
    preselectGeom() {
      const { fragmentsHighlighter, fragmentsHighlighter2 } = store.getters

      fragmentsHighlighter.highlight('preselection', true)
      fragmentsHighlighter.update()

      if (fragmentsHighlighter2) {
        fragmentsHighlighter2.highlight('preselection', true)
        fragmentsHighlighter2.update()
      }
    },

    /** Shows or hides CubeMap */
    toggleShowCubeMap(show) {
      const cube = document.getElementById('tooeen-cube-map')
      cube.style.zIndex = show ? '10' : '-1'
    },

    showBrowser() {
      showBrowser()
    }
    //#endregion
  },

  watch: {
    angleMode() {
      _this.checkModes()
    },

    areaMode() {
      _this.checkModes()
    },

    clippingPlanesSize() {
      store.getters.tools.clipperTool.size = _this.clippingPlanesSize
    },

    ghostMode() {
      _this.ghostMode ? _this.setGhostMode() : _this.resetGhostMode()
    },

    ghostColor() {
      if (_this.ghostMode) _this.setGhostMode()
    },

    async ifcFile() {
      if (_this.ifcFile) {
        await _this.resetViewer()
        await _this.parseFilesFromIfc(_this.ifcFile, null, null, false)
        window.dispatchEvent(new Event('resize'))
      }
    },

    ifcLevel() {
      _this.inputIfcLevel.base.value = _this.ifcLevel.baseHeight
      _this.inputIfcLevel.height.value = _this.ifcLevel.height
    },

    loading() {
      document.body.style.cursor = _this.loading ? 'progress' : ''
    },

    pointMode() {
      _this.checkModes()
    },

    sao() {
      if (_this.showGhosting) {
        for (const key in _this.sao) {
          _this.viewer._renderer.postproduction.sao[key] = parseFloat(
            _this.sao[key]
          )
        }
      }
    },

    selectionWindow() {
      if (_this.selectionWindow) {
        document.body.style.cursor = 'alias'

        setTimeout(() => {
          watchSelectionWindow()
        }, 100)
      } else {
        document.body.style.cursor = 'default'
      }
    },

    showGhosting() {
      _this.viewer._renderer.postproduction.enabled = _this.showGhosting
      _this.viewer._renderer.postproduction.active = _this.showGhosting
      _this.viewer._renderer.postproduction.visible = _this.showGhosting
    },

    snapMode() {
      _this.checkModes()
    },

    submit() {
      const key = Object.keys(_this.submit)[0]

      const params = _this.submit[key]

      if (params.length) {
        eval(`_this.${key}(${paramsStr(params)})`)
      } else {
        eval(`_this.${key}()`)
      }
    },

    toggleCutplanes() {
      store.dispatch('setClippingPlanesMode', !_this.clippingPlanesMode)
    },

    viewerMode() {
      if (_this.viewerMode === 1) {
        _this.rightFloating = 0
        _this.topFloating = 0
        window.dispatchEvent(new Event('resize'))
      }

      if (_this.viewerMode === 2) {
        _this.rightFloating = window.innerWidth - 350

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }

      if (
        _this.viewerMode === 1 ||
        (_this.viewerMode === 2 && !_this.subViewerInstanced)
      ) {
        setTimeout(() => {
          mount2DViewer()
          _this.subViewerInstanced = true
        }, 700)
      }
    },

    volumeTraceMode() {
      _this.checkModes()
    },

    zoomObjectMode() {
      if (!_this.zoomObjectMode) {
        document.body.style.cursor = 'default'
      } else {
        document.body.style.cursor = 'zoom-in'
      }
    },

    zoomWindowMode() {
      document.body.style.cursor = _this.zoomWindowMode ? 'zoom-in' : 'default'
    }
  }
  //#endregion
}
</script>
