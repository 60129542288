import defineMeshes from '@/controllers/renderGlb/define-meshes'
import fileNames from '@/config/file-names'
import store from '@/store'

/** Based on model, take advantage of fragments instance
 * to parse obtained files from the original .ifc mode
 * @param model result of viewer-loading
 * @returns File[] */
export default async model => {
  const files = []

  const { fragments } = store.getters

  /** Reads received key and accumulates related one to files-list */
  const pushFile = key => {
    const content = [JSON.stringify(model[key])]
    files.push(new File(content, fileNames[key]))
  }

  /** Loop references */
  for (const key of Object.keys(fileNames)) {
    pushFile(key)
  }

  defineMeshes(model.items)

  return {
    files,
    geometriesBinaries: fragments.export(fragments.groups[0])
  }
}
