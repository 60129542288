import store from '@/store'
import getResult from '@/controllers/highlight/get-result'
import highlightResult from '@/controllers/highlight/highlight-result'
import getItemProperties from '@/controllers/get-item-properties'

export default async () => {
  const result = getResult(true)

  highlightResult(result, true)

  if (result) {
    const { id, fragments } = result

    const fragmentId = fragments[0].id

    const itemProperties = getItemProperties(id, fragmentId)

    await store.dispatch('setObjectProperties', itemProperties)

    return itemProperties
  } else {
    await store.dispatch('setObjectProperties', null)
  }
}
