import store from '@/store'

/** Obtains data about user clicks at geometries */
export default removePrevious => {
  const { fragmentsHighlighter, viewer } = store.getters

  viewer.raycaster.mouse.offset = { x: 0, y: 0 }

  return fragmentsHighlighter.highlight('highlight', removePrevious)
}
