import setFragmentFiles from './set-fragment-files'
import store from '@/store'
import { FragmentIfcLoader } from 'openbim-components'

/** It loads and obtains the parsed gltb & json files
 * using fragments & objectURL from uploaded file
 * @param file an arrayBuffer read from the file
 * @returns File[] */
export default async file => {
  const { fragments, viewer } = store.getters

  const fragmentIfcLoader = new FragmentIfcLoader(viewer, fragments)

  fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = false
  fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true
  fragmentIfcLoader.settings.optionalCategories = []

  const model = await fragmentIfcLoader.load(file)

  viewer.scene.get().add(model)

  return await setFragmentFiles(model)
}
