import mutations from './mutations'

let actions = {}

for (const m in mutations) {
  actions = {
    ...actions,
    [m]: (context, payload) => context.commit(m, payload)
  }
}

export default actions
